






















import { Component } from "vue-property-decorator";
import { BaseOperationPropertyVue } from "fs-pb-vue2/vueModels/operations/properies/BaseOperationPropertyVue";
import CardSelector from "./CardSelector.vue";

@Component({
  components: {
    CardSelector
  }
})
export default class CardSelectProperty extends BaseOperationPropertyVue {}
