


















































import { AliasEditModalVue } from "fs-pb-vue2/vueModels/settings/AliasEditModalVue";
import { Component } from "vue-property-decorator";

@Component({})
export default class EsiaRegistrationModal extends AliasEditModalVue {
  openAnotherSite(e, link: string) {
    e.preventDefault();
    window.open(this.$options.filters.url(link), "_blank");
  }
}
