import { inject, injectable } from "inversify";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import IErrorService from "fs-pb-vue2/services/error/IErrorService";
import CardPinService from "fs-pb-vue2/services/cardOperations/CardPinService";
import i18n from "@/i18n";
import { CardPinModelFS } from "../../models/cardOperations/CardPinModelFS";
import { OperationsApi } from "fs-pb-vue2/services/api/OperationsApi";

/**Сервис проведения операций */
@injectable()
export default class CardPinServiceFS extends CardPinService {
  constructor(
    @inject(DiTokens.AlertService) public alertService: IAlertService,
    @inject(DiTokens.ErrorService) public errorService: IErrorService
  ) {
    super(alertService, errorService);
  }

  /**
   * Инициализация операции
   * @param initData Данные для инициализации
   * */
  public async init(params: any): Promise<CardPinModelFS> {
    const operationData = new CardPinModelFS(params);
    //const declaration = await OperationsApi.getDeclarationAsync(params.code, this.configStore.apiData.OperationCodeOfToOtherContractByCard, this.configStore.apiData.OperationCodeOfToExt);
    operationData.RespondentCode = params.code;
    return operationData;
  }


  /**
   * Действия после проведения операции
   */
  public async afterExecute(
    model: CardPinModelFS
  ): Promise<void> {
    if (model.ActionStateText && model.RequestState === 3) {
      this.alertService.error(model.ActionStateText);
      return;
    }
    this.alertService.success(
      i18n
        .t(
          `main.cards.messages.PINchanged`,
          { pan: model.card.Data.Number }
        )
        .toString()
    );
  }
}