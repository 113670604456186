


























































































import { AuthHistoryVue } from "fs-pb-vue2/vueModels/authHistory/AuthHistoryVue";
import { Component } from "vue-property-decorator";
import BackLink from "@/components/app/BackLink.vue";
import DatePicker from "fs-pb-vue2/components/datePicker/DatePicker.vue";

@Component({
  components: { BackLink, DatePicker }
})
export default class AuthHistory extends AuthHistoryVue {
  mounted() {
    this.init();
  }
}
