import { FinServRecoveryModel } from "@/extensions/models/auth/FinServRecoveryModel";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import RecoveryService from "fs-pb-vue2/services/auth/operations/RecoveryService";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import IErrorService from "fs-pb-vue2/services/error/IErrorService";
import { setCookie } from "fs-pb-vue2/utilities/cookieHelper";
import { inject, injectable } from "inversify";

/**Сервис восстановления доступа*/
@injectable()
export default class FinServRecoveryService extends RecoveryService {
  constructor(
    @inject(DiTokens.AlertService) public alertService: IAlertService,
    @inject(DiTokens.ErrorService) public errorService: IErrorService
  ) {
    super(alertService, errorService);
  }

  /**
 * Инициализация операции
 * @param initData Данные для инициализации
 * */
  public async init(): Promise<FinServRecoveryModel> {
    if(!Object.keys(this.configStore.phoneFormatDir.FormatDict).length)
    {
      if(!this.configStore.apiData?.PhoneFormatDirFile) {
        await this.configStore.fetchApiState();
      }
      await this.configStore.fetchPhoneFormatDir();
    }

    const operationData = new FinServRecoveryModel(this.configStore.phoneFormatDir);
    operationData.RespondentCode =
      this.configStore.apiData.OperationCodeOfRecoveryPassword || "RecoveryPassword";
    return operationData;
  }

  public async afterExecute(model: FinServRecoveryModel): Promise<any> {
    this.authStore.setMsg(model.SuccessMessage);
    this.authStore.setLogin(model.Pan);
    setCookie("loginMode", "pan");
    setCookie('isOldClient', 'true');
  }
}
