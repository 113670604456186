


































































































































































































































































// import BackLink from "@/components/app/BackLink.vue";
import { CatalogVueFS } from "@/extensions/catalog/CatalogVueFS";
import CatalogIcon from "@/components/catalog/CatalogIcon.vue";
import SearchInput from "fs-pb-vue2/components/SearchInput.vue";
import OperationModal from "@/components/history/OperationModal.vue";
import { Component } from "vue-property-decorator";
import { CatalogData, CatalogsTypes } from "fs-pb-vue2/data";
import { searchCatalogById } from "fs-pb-vue2/utilities/index";
import BackLink from "@/components/app/BackLink.vue";
import i18n from "@/i18n";
import { urlFilter } from "fs-pb-vue2/filters";

@Component({
  components: { CatalogIcon, SearchInput, BackLink, OperationModal },
})
export default class Catalog extends CatalogVueFS {
  async mounted() {
    await this.getCharges();
    const regions = await this.catalogService.fetchRegions();
    if (regions) regions.forEach((reg: any) => this.regions.push(reg.Name));
    if (this.$route.query.search) {
      this.query = this.$route.query.search;
      this.filtersWrap = true;
      this.searchCatalog({
        query: this.query,
        city: null,
      });
    } else {
      this.clearCatalog();
      await this.initOperationModal();
    }
  }

  isMe2Me: boolean = false;

  get upToContractCatalog() {
    const ids: Array<CatalogData> = [];
    if (this.configStore.apiData.OperationCodeOfToContract) {
      // const toContract: CatalogData = searchCatalogById(
      //   this.catalogStore.catalog,
      //   this.configStore.apiData.OperationCodeOfToContract
      // );
      const toContract: CatalogData = new CatalogData();
      toContract.CatalogType = CatalogsTypes.Operation;
      toContract.Id = parseInt(
        this.configStore.apiData.OperationCodeOfToContract
      );
      toContract.Caption = i18n.t("main.catalog.fromFinserv");
      toContract.Picture = urlFilter("/Content/img/icons/finserv_icon.svg");
      if (this.activeContractsLength > 1)
        ids.push(toContract);
    }

    if (this.configStore.apiData.OperationCodeOfMe2MeOperation) {
      const sbp: CatalogData = new CatalogData();
      sbp.CatalogType = CatalogsTypes.Operation;
      sbp.Id = parseInt(this.configStore.apiData.OperationCodeOfMe2MeOperation);
      sbp.Caption = i18n.t("main.catalog.fromOtherBank");
      sbp.Picture = urlFilter("/Content/img/sbp_logo.png");
      ids.push(sbp);
    }
    return ids;
  }

  async toCategoryExt(oper: CatalogData) {
    await super.toCategory(oper);
  }

  async toCategory(oper: CatalogData) {
    // if (oper.Caption.toLowerCase() == "себе") {
    if (
      oper.Id.toString() == this.configStore.apiData.OperationCodeOfToContract
    ) {
      this.isMe2Me = true;
    } else {
      await super.toCategory(oper);
    }
  }

  async searchCatalog(e: any) {
    this.closeMe2Me();
    super.searchCatalog(e);
  }

  closeMe2Me() {
    this.isMe2Me = false;
  }

  showAtm() {
    window.open("https://www.finsb.ru/about/offices/", "_blank");
  }
}
