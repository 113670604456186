<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <v-overlay class="main-preloader" :value="$store.getters['app/getLoading']">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import "vue-toast-notification/dist/theme-sugar.css";
// import "vue-smooth-picker/dist/css/style.css";
import "vue-scroll-picker/dist/style.css"

/** Главный шаблон приложения */
export default Vue.extend({
  name: "App",
  data: () => ({}),
  components: {
    /**Подключаем лэйауты */
    /**Лэйаут для авторизации */
    AuthLayout,
    /**Лэйаут для авторизованных страниц */
    MainLayout,
    /**Лэйаут по умолчанию (если не задан в router) */
    EmptyLayout
  },
  computed: {
    /**Вычисляем имя лэйаута*/
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout"; //TODO:до загрузки лэйаута происходит мерцание и сперва загружается empty
    }
  }
});
</script>
<style lang="scss">
/**Подключаем шрифты */
@font-face {
  font-family: "MyriadPro";
  src: local("Raleway"),
    url(./assets/fonts/Myriadpro-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro"),
    url(./assets/fonts/Myriadpro-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro"),
    url(./assets/fonts/Myriadpro-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro"), 
    url(./assets/fonts/Myriadpro-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
</style>
