




































































import { OperationsFilterVue } from "fs-pb-vue2/vueModels/history/OperationsFilterVue";
import { Component } from "vue-property-decorator";

@Component
export default class OperationsFilter extends OperationsFilterVue {}
