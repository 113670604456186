





















































































































































import { ContractBlockVueFS } from "@/extensions/contracts-panel/ContractBlockVueFS";
import { Component } from "vue-property-decorator";

@Component({})
export default class ContractBlock extends ContractBlockVueFS {
  mounted() {
    if (this.contract.Cards.length > 0) {
      this.contract.Cards.map((card) => {
        if (card) 
          this.getColor(card)
      });
    }
  }
}
