











import ServerCommandsModal from "@/components/app/ServerCommandsModal.vue";
import { ServerCommandsControllerVue } from "fs-pb-vue2/vueModels/app/ServerCommandsControllerVue";
import { Component } from "vue-property-decorator";

@Component({
  components: { ServerCommandsModal }
})
export default class ServerCommandsController extends ServerCommandsControllerVue {
  async created() {
    await this.init();
  }
}
