


























































































































































































































import i18n from "@/i18n";
import Vue from "vue";
import VerifyBox from "@/components/app/VerifyBox.vue";
import { SubscriptionEditVue } from "fs-pb-vue2/vueModels/subscription/SubscriptionEditVue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    VerifyBox
  }
})
export default class SubscriptionEdit extends SubscriptionEditVue {
  async mounted() {
    this.selectedCategory = null;
    if (this.$refs.subsForm) this.$refs.subsForm.resetValidation();
    if (this.catImages.length === 0) {
      await this.getCategorysImages();
    }
    // this.fillData(this.item);
  }

  get customTokens(): any {
    return this.appStore.customTokens;
  }
}

