import { DeclarationPropertiesTypes } from "fs-pb-vue2//data/enums/declarationPropertiesTypes";
import { OperationRequestData } from "fs-pb-vue2//data/operationRequestData";
import i18n from "@/i18n";
import { RegisterModel } from "fs-pb-vue2/models/auth/RegisterModel";
import { PhoneFormat, PhoneFormatDir } from "fs-pb-vue2/data/phoneFormat";

/** Модель регистрации меткома */
export class FinServRegisterModel extends RegisterModel {
  /**Номер карты */
  public Pan: string = "";

  /**Правила заполнения номера карты */
  public PanRules: Array<Function> = [
    (p: any) => !!p || i18n.t("auth.errors.panEmpty"),
    (p: any) =>
      (p && /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/.test(p)) ||
      i18n.t("auth.errors.panCheck")
  ];

  /**Маска номера карты */
  public PanMask: string = '#### #### #### ####';

  /**Маска телефона*/
  // public PhoneMask: string = '+###############';
  // /**Номер телефона */
  // public Phone: string = "";

  public PhoneRightValue: string = "";

  public SelectedFormat: PhoneFormat = new PhoneFormat();

  public Formats: PhoneFormat[] = [];

  public FormatsExists: boolean = false;
  
  constructor(formats: PhoneFormatDir | null = null) {
    super();

    // if (formats.Operations?.RegistrationPerson) {
    //   this.FormatsExists = true;
    //   formats.Operations?.RegistrationPerson.Formats.forEach((f: string) => {
    //     if (formats.FormatDict[f])
    //       this.Formats.push(formats.FormatDict[f]);
    //   });

    //   this.SelectedFormat = this.Formats[0];
    // }
  }

  /**
   * Создание запроса операции.
   */
  public createOperationRequest(): OperationRequestData {
    const ord = new OperationRequestData();
    ord.respondentCode = this.RespondentCode;
    ord.operationId = this.OperationId;

    
    // if(this.FormatsExists) {
    //   this.Phone = `${this.SelectedFormat.DisplayCode}${this.PhoneRightValue}`
    // }
    
    ord.setProperty("surname", "", DeclarationPropertiesTypes.String);
    ord.setProperty("name", "", DeclarationPropertiesTypes.String);
    ord.setProperty("secondname", "", DeclarationPropertiesTypes.String);
    ord.setProperty("mainDocNumber", "", DeclarationPropertiesTypes.String);
    ord.setProperty("account", "", DeclarationPropertiesTypes.String);
    ord.setProperty("inn", "", DeclarationPropertiesTypes.String);
    ord.setProperty("phone", this.Pan.replace(/\s/g, ""), DeclarationPropertiesTypes.String);
    ord.setProperty("codeword", "", DeclarationPropertiesTypes.String);

    return ord;
  }
}
