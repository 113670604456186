




























































































































import BackLink from "@/components/app/BackLink.vue";
import { NotificationsVue } from "fs-pb-vue2/vueModels/notifications/NotificationsVue";
import { Component } from "vue-property-decorator";

@Component({
  components: { BackLink }
})
export default class Notifications extends NotificationsVue {}
