












































































































































































































































































































































































































import { BaseContractVueFS } from "@/extensions/contract/BaseContractVueFS";
import ContractDetails from "@/components/contracts/ContractDetails.vue";
import { Component } from "vue-property-decorator";
import Requisites from "@/components/contracts/Requisites.vue";
import BackLink from "@/components/app/BackLink.vue";
import OperationsList from "@/components/history/OperationsList.vue";
import ContractSmsModal from "@/components/contractOperations/ContractSmsModal.vue";
import { getRandInt } from "fs-pb-vue2/utilities/index";

@Component({
  components: {
    Requisites,
    ContractDetails,
    BackLink,
    OperationsList,
    ContractSmsModal
  }
})
export default class BaseContract extends BaseContractVueFS {

  mounted() {
    if (this.$route.query.reqs === "true") {
      this.showRequisites();
    }
  }

  getRandWidth() {
    return getRandInt(30, 100);
  }

  get getSumValue(): string {
    const contract: any = this.contract;
    return `${contract.Data.ContractType == 1 || contract.Data.ContractType == 2 ? contract.Rest : contract.DisposableLimit} ${contract.Currency}`;
  }
}
