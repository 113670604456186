

























































































































import { RequisitesVue } from "fs-pb-vue2/vueModels/contracts/RequisitesVue";
import DetailsList from "@/components/app/DetailsList.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: { DetailsList }
})
export default class Requisites extends RequisitesVue {
  async mounted() {
    await this.init();
  }
}
