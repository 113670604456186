









import Vue from "vue";
import BaseContract from "@/components/contracts/BaseContract.vue";
import SearchInput from "fs-pb-vue2/components/SearchInput.vue";
import Slider from "@/components/app/Slider.vue";
import Card from "@/components/contracts/Card.vue";
import { getModule } from "vuex-module-decorators";
import { ContractsStore } from "fs-pb-vue2/store/modules/contracts";

/**Страница ифнормации по счету */
export default Vue.extend({
  components: {
    Card,
    Slider,
    SearchInput,
    BaseContract
  },
  data: () => ({
    query: ''
  }),
  computed: {
    contractStore(): ContractsStore {
      return getModule(ContractsStore, this.$store);
    },
    contractId(): string {
      if (!this.isCard)
        this.contractStore.setSelected(this.$route.params.contractId);
      return this.$route.params.contractId;
    },
    cardId(): string {
      if (this.$route.params.cardId)
        this.contractStore.setSelected(this.$route.params.cardId);
      return this.$route.params.cardId;
    },
    isCard(): boolean {
      return !!this.cardId;
    }
  },
  methods: {
    searchCatalog(e: any) {
      this.$router.push(`/payments?search=${e.query}`);
    }
  }
});
