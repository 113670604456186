import { CardPinModelFS } from "../models/cardOperations/CardPinModelFS";
import { CardPinModalVue } from "fs-pb-vue2/vueModels/cardOperations/CardPinModalVue";
import IOperationService from "fs-pb-vue2/services/operations/IOperationService";
import { container } from "@/inversify.config";
import { DiTokens } from "fs-pb-vue2/services/diTokens";

/**
 *  Класс для компоненты модального окна смены PIN карты.
 */
export class CardPinModalVueFS extends CardPinModalVue {
  /**Инициализация операции */
  async initOperation(): Promise<void> {
    this.loading = true;
    this.service = container.get<IOperationService>(DiTokens.CardPinService);
    this.OperationModel = (await this.operationService.init({
      code: this.respondentCode,
      card: this.params.card
    })) as CardPinModelFS;
    this.operationInitData['card'] = this.params.card?.Data.ReferenceId;
    this.loading = false;
  }
}
