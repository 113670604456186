




















import { CatalogIconVue } from "fs-pb-vue2/vueModels/catalog/CatalogIconVue";
import { Component } from "vue-property-decorator";

@Component({})
export default class CatalogIcon extends CatalogIconVue {}
