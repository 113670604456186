import { Prop, Watch, Vue } from "vue-property-decorator";
import i18n from "@/i18n";

export class MonthPickerVueFS extends Vue {
  @Prop() defValue!: Array<string>;

  @Prop() endYear!: number;

  @Prop() startYear!: number;

  @Prop() isShow!: boolean;

  /** Нижняя граница */
  @Prop() minDate!: string;

  /** Верхняя граница */
  @Prop() maxDate!: string;

  // @Watch('minDate', { immediate: true })
  // minChanged(val: string) {
  //   this.init();
  // }

  // @Watch('maxDate', { immediate: true })
  // maxChanged(val: string) {
  //   this.init();
  // }

  @Watch('selectYear', { immediate: true })
  yearChanged(val: string): void {
    if (val)
      this.cutMonths();
  }

  defMonths: any = [];

  defYears: any = [];

  selectMonth: string = '';

  selectYear: string = '';

  get years(): Array<number> {
    // const nowYear = new Date().getFullYear();
    const years: Array<number> = [];
    for (let i = +this.startYear; i <= +this.endYear; i++) {
      years.push(i);
    }
    return years;
  }

  cutMonths(): void {
    this.defMonths = [];
    this.fillMonths();
    const minMonth = +this.minDate.substring(5, 7) - 1;
    const maxMonth = +this.maxDate.substring(5, 7);
    if (this.selectYear == this.minDate.substring(0, 4)) {
      this.defMonths = this.defMonths.slice(minMonth, this.localeMonths.length);
    }  
    if (this.selectYear == this.maxDate.substring(0, 4)) {
      this.defMonths = this.defMonths.slice(0, maxMonth);
    }
  }

  fillMonths(): void {
    this.localeMonths.map((month: string, i: number) => this.defMonths.push({
      name: month,
      value: i >= 9 ? (i + 1).toString() : `0${i + 1}`
    }));
  }

  clearData(): void {
    this.defMonths = [];
    this.defYears = [];
    this.selectMonth = '';
    this.selectYear = '';
  }

  init(): void {
    this.clearData();
    this.fillMonths();
    this.years.map((year: number) => this.defYears.push({
      name: year,
      value: year.toString()
    }));
    if (this.defValue && this.defValue[0]) {
      this.selectMonth = this.defValue[0].substring(5, 7);
      this.selectYear = this.defValue[0].substring(0, 4);
    } else {
      this.selectMonth = this.defMonths[0];
      this.selectYear = this.defYears[0];
    }
    this.cutMonths();
    if (this.$refs.picker)
      this.$refs.picker.resize();
    this.isMounted = true;
  }

  /** Флаг моунта компонента */
  isMounted: boolean = false;

  localeMonths: Array<string> = Object.keys(i18n.t("common.months")).map(
    (item: string) => {
      return i18n.t(`common.months.${item}`).toString();
    }
  );

  /** Память для watcher года в smooth datepicker */
  checkYearChanged: boolean = false;

  @Watch("isShow", { immediate: true })
  showChange(val: any): void {
    if (val)
      this.init();
  }

  /** Закрытие пикера */
  hidePicker(): void {
    this.$emit("hidePicker");
  }

  confirm(): void {
    let lastDay = new Date(+this.selectYear, parseInt(this.selectMonth.toString()), 0).getDate();
    if (lastDay > new Date().getDate() && (new Date().getMonth() + 1) === parseInt(this.selectMonth.toString())) {
      lastDay = new Date().getDate();
    }

    this.$emit('changeMonthValue', {
      period: [`${this.selectYear}-${this.selectMonth}-01`, `${this.selectYear}-${this.selectMonth}-${lastDay}`]
    });
    this.hidePicker();
  }
}
