






























































































































import { ServerCommandsModalVue } from "fs-pb-vue2/vueModels/app/ServerCommandsModalVue";
import { Component } from "vue-property-decorator";
import VerifyBox from "@/components/app/VerifyBox.vue";

@Component({
  components: { VerifyBox }
})
export default class ServerCommandsModal extends ServerCommandsModalVue {
  async mounted() {
    await this.init();
  }
}
