

















































































import { SbpEnableModalVue } from "fs-pb-vue2/vueModels/settings/SbpEnableModalVue";
import { Component } from "vue-property-decorator";
import VerifyBox from "@/components/app/VerifyBox.vue";

@Component({
  components: { VerifyBox }
})
export default class SbpEnableModal extends SbpEnableModalVue {
  async mounted() {
    await this.init();
  }
}
