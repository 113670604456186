


































import { MonthPickerVueFS } from "@/extensions/app/MonthPickerVueFS";
import { Component, Vue } from "vue-property-decorator";
import VueScrollPicker from "vue-scroll-picker"

Vue.use(VueScrollPicker)


@Component
export default class MonthPicker extends MonthPickerVueFS {
  mounted() {
    this.$forceUpdate();
    // this.init();
  }
}
