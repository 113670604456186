import { inject, injectable } from "inversify";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import IErrorService from "fs-pb-vue2/services/error/IErrorService";
import { FinServRegisterModel } from "@/extensions/models/auth/FinServRegisterModel";
import AnonymousOperationService from "fs-pb-vue2/services/operations/AnonymousOperationService";
import i18n from "@/i18n";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";
import { getModule } from "vuex-module-decorators";
import { AuthStore } from "fs-pb-vue2/store/modules/auth";
import storeAccessor from "fs-pb-vue2/store/storeAccessor";
import { setCookie } from "fs-pb-vue2/utilities/cookieHelper";

/**Сервис проведения операций */
@injectable()
export default class FinServRegisterService extends AnonymousOperationService {
  constructor(
    @inject(DiTokens.AlertService) public alertService: IAlertService,
    @inject(DiTokens.ErrorService) public errorService: IErrorService
  ) {
    super(alertService, errorService);
  }
  /**
   * Инициализация операции
   * @param initData Данные для инициализации
   * */
  public async init(): Promise<FinServRegisterModel> {
    
    if(!Object.keys(this.configStore.phoneFormatDir.FormatDict).length)
    {
      if(!this.configStore.apiData?.PhoneFormatDirFile) {
        await this.configStore.fetchApiState();
      }
      await this.configStore.fetchPhoneFormatDir();
    }

    const operationData = new FinServRegisterModel(this.configStore.phoneFormatDir);


    operationData.RespondentCode =
      this.configStore.apiData.OperationCodeOfRegistration || "RegistrationPerson";
    return operationData;
  }

  public async nextStep(model: FinServRegisterModel): Promise<FinServRegisterModel> {
    model = (await this.execute(model)) as FinServRegisterModel;
    return model;
  }

  public async execute(actionModel: FinServRegisterModel): Promise<FinServRegisterModel> {
    try {
      actionModel = await super.execute(actionModel) as FinServRegisterModel;
    } catch (e) {
      actionModel.ActionStateText = e.message;
      if (e.message.includes('По указанным данным найден действующий договор ДБО.')) {
        this.alertService.error(i18n.t('auth.messages.allreadyRegistered').toString(), false);
      } else
        await this.errorService.errorHandler(e, false, false, "");
    }
    return actionModel;
  }

  /**
 * Действия после проведения операции
 * @param args
 */
  public async afterExecute(model: FinServRegisterModel): Promise<any> {
    this.authStore.setLogin(model.Pan);
    this.authStore.setMsg(model.SuccessMessage);
    setCookie("loginMode", "pan");
    setCookie('isOldClient', 'true');
  }

  /**Стор настроек */
  get configStore(): ConfigStore {
    return getModule(ConfigStore, storeAccessor.store);
  }

  /**Стор настроек */
  get authStore(): AuthStore {
    return getModule(AuthStore, storeAccessor.store);
  }
}
