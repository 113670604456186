











































import { AliasEditModalVue } from "fs-pb-vue2/vueModels/settings/AliasEditModalVue";
import { Component } from "vue-property-decorator";

@Component({})
export default class AliasEditModal extends AliasEditModalVue {}
