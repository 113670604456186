































import { SliderVueFS } from "@/extensions/app/SliderVueFS";
import { Component } from "vue-property-decorator";

@Component({})
export default class Slider extends SliderVueFS {
  created() {
    window.addEventListener("resize", this.updateWidth);
  }
  mounted() {
    this.isMounted = true;
    this.updateWidth();
  }
}
