



































import { BackLinkVue } from "fs-pb-vue2/vueModels/app/BackLinkVue";
import { Component } from "vue-property-decorator";

@Component({})
export default class BackLink extends BackLinkVue {}
