








import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ConfigApi } from "fs-pb-vue2/services/api/ConfigApi";
import { getModule } from "vuex-module-decorators";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";
import { PersonStore } from "fs-pb-vue2/store/modules/person";

@Component({
})
export default class Cashback extends Vue {

  srcUrl: string = '';

  get configStore(): ConfigStore {
    return getModule(ConfigStore, this.$store);
  }

  get personStore(): PersonStore {
    return getModule(PersonStore, this.$store);
  }

  async mounted(): Promise<void> {

    if (this.personStore.personInfo?.Data?.Mode !== 1 && !this.configStore.apiData?.CashbackEnable) {
      this.$router.back();
      return;
    }

    try {
      const respData: any = await ConfigApi.getCashBackPage();
      this.srcUrl = await ConfigApi.getCashBackUrl();

      const iframe: any = document.getElementById('cashback');

      iframe.setAttribute('scrolling', 'yes');
      iframe.setAttribute('sandbox', 'allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-presentation allow-scripts allow-forms');
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(respData.data);
      iframe.contentWindow.document.close();
    } catch(e: any) {
      this.$router.back();
      console.log(e.message);
    }

  }

}
