












































































































































































































































































import { SettingsVue } from "fs-pb-vue2/vueModels/settings/SettingsVue";
import AliasEditModal from "@/components/settings/AliasEditModal.vue";
import PasswordEditModal from "@/components/settings/PasswordEditModal.vue";
import SbpEnableModal from "@/components/settings/SbpEnableModal.vue";
import SbpDefaultModal from "@/components/settings/SbpDefaultModal.vue";
import DefaultOutAccount from "@/components/settings/DefaultOutAccount.vue";
import AccountInDefaultModal from "@/components/settings/AccountInDefaultModal.vue";
import BankSelectModal from "@/components/settings/BankSelectModal.vue";
import EsiaRegistrationModal from "@/components/settings/EsiaRegistrationModal.vue";
import { Component } from "vue-property-decorator";
import ActionsList from "@/components/app/ActionsList.vue";
import BackLink from "@/components/app/BackLink.vue";
import SbpDeleteDefaultModal from "@/components/settings/SbpDeleteDefaultModal.vue";


@Component({
  components: {
    AliasEditModal,
    PasswordEditModal,
    SbpEnableModal,
    SbpDefaultModal,
    ActionsList,
    DefaultOutAccount,
    AccountInDefaultModal,
    BankSelectModal,
    BackLink,
    EsiaRegistrationModal,
    SbpDeleteDefaultModal
  }
})
export default class Settings extends SettingsVue {}
