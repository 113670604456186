import { Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ContractsStore } from "fs-pb-vue2/store/modules/contracts";
import { container } from "@/inversify.config";
import { jsUcfirst } from "fs-pb-vue2/utilities/index";
import { OperationsListVue } from "fs-pb-vue2/vueModels/history/OperationsListVue";
import { OperationDetailsModel } from "fs-pb-vue2/models/operations/operationDetailsModel";
import { OperationRequestData } from "fs-pb-vue2/data/operationRequestData";
import analyze from 'rgbaster';
import i18n from "@/i18n";

export class OperationsListVueFS extends OperationsListVue {

  /**Стор настроек */
  get contractsStore(): ContractsStore {
    return getModule(ContractsStore, this.$store);
  }

  selectedColor: string = '';

  get periodText() {
    if (this.months.length > 0 && this.model.isPeriod) {
      const first = this.months[0]?.split('-').reverse().join(".");
      const second = this.months[1]?.split('-').reverse().join(".");

      return `${first ? first : ''}${first && second ? ' - ' : ''}${second ? second : ''}`;
    } else if (this.months[0] && !this.model.isPeriod) {
      const month: string = jsUcfirst(
        this.localeMonths[+this.months[0].substring(5, 7) - 1]
      );
      const year: number = +this.months[0].substring(0, 4);
      return `${month} ${year}`;
    }
    return "";
  }

  async showOperInfoDialog(operation: any) {
    this.modalLoading = true;
    this.operInfoDialog = true;
    this.modalDetails = await this.dynamicService.getOperationDetails(
      operation
    );
    if (!this.modalDetails.OperationData.RequestState) {
      this.modalDetails.OperationData.RequestState = this.modalDetails.RequestData.requestState;
      this.modalDetails.OperationData.Sum = this.modalDetails.RequestData.values[this.modalDetails.RequestData.keys.indexOf('sum')];
      this.modalDetails.OperationData.IsRespondentActive = this.modalDetails.RequestData.isRespondentActive;
      const contractCur: string = this.contractsStore.contracts.getContractByRefId(this.modalDetails.RequestData.contractRef)?.Data.CurrencyCharCode;
      this.modalDetails.OperationData.Currency = this.$options.filters.currency(contractCur);
    }
    if (operation.sbpDetails) {
      this.modalDetails.sbpDetails = operation.sbpDetails;
    }
    // this.fillStatementDetails();
    // Раскрасска хидера в цвет картинки
    try {
      let pict;
      if (!operation.Picture) {
        pict = require('./../../../public/img/default-oper.png');
      } else {
        pict = operation.Picture;
      }
      const result = await analyze(pict);
      this.selectedColor = result[0].color;
    } catch(e) {
      console.log(e);
      this.selectedColor = '';
    }    
    this.modalLoading = false;
  }

  fillStatementDetails() {
    this.modalDetails.Details = [];
    if (this.model.token == 'CardStatementService') {
      this.modalDetails.Details = [
        {
          //Дата зачисления/списания
          Key: i18n.t("main.operations.cardStatementData.RegDate"),
          Value: this.modalDetails.OperationData.RegDate,
        },
        {
          //Дата совершения платежа
          Key: i18n.t("main.operations.cardStatementData.CommitTime"),
          Value: this.$options.filters.date(
            this.modalDetails.OperationData.CommitTime,
            "dateext"
          ),
        },
        {
          //Код авторизации
          Key: i18n.t("main.operations.cardStatementData.AuthCode"),
          Value: this.modalDetails.OperationData.AuthCode,
        },
        {
          //Уникальный ИД транзакции
          Key: i18n.t("main.operations.cardStatementData.UniqueID"),
          Value: this.modalDetails.OperationData.UniqueID,
        },
        {
          //Сумма
          Key: i18n.t("main.operations.cardStatementData.Sum"),
          Value: this.modalDetails.OperationData.Sum
            ? this.$options.filters.sum(
                this.modalDetails.OperationData.Sum,
                false,
                this.modalDetails.OperationData.Currency
              )
            : "",
        },
        {
          //Комиссия
          Key: i18n.t("main.operations.cardStatementData.FeeSum"),
          Value: this.modalDetails.OperationData.FeeSum
            ? this.$options.filters.sum(
                this.modalDetails.OperationData.FeeSum,
                false,
                this.modalDetails.OperationData.Currency
              )
            : "",
        },
        {
          //Сумма транзакции
          Key: i18n.t("main.operations.cardStatementData.TransSum"),
          Value: this.modalDetails.OperationData.TransSum
            ? this.$options.filters.sum(
                this.modalDetails.OperationData.TransSum,
                false,
                this.modalDetails.OperationData.Currency
              )
            : "",
        },
        // {
        //   //Код валюты
        //   Key: i18n.t("main.operations.cardStatementData.TransCurCode"),
        //   Value: this.modalDetails.OperationData.TransCurCode,
        // },
        {
          //Код продавца
          Key: i18n.t("main.operations.cardStatementData.MCC"),
          Value: this.modalDetails.OperationData.MCC,
        },
        {
          //Описание продавца
          Key: i18n.t("main.operations.cardStatementData.MCCDesc"),
          Value: this.modalDetails.OperationData.MCCDesc,
        },
        {
          //Наименование МСС
          Key: i18n.t("main.operations.cardStatementData.MCCName"),
          Value: this.modalDetails.OperationData.MCCName,
        },
        {
          //Описание транзакции
          Key: i18n.t("main.operations.cardStatementData.Description"),
          Value: this.modalDetails.OperationData.Description,
        },
        {
          //Адрес проведения операции
          Key: i18n.t("main.operations.cardStatementData.Address"),
          Value: this.modalDetails.OperationData.Address,
        },
      ];
    } else if (this.model.token == 'ContractStatementService') {
      this.modalDetails.Details = [
        {
          //Дата зачисления/списания
          Key: i18n.t("main.operations.contractStatementData.RegDate"),
          Value: this.$options.filters.date(
            this.modalDetails.OperationData.modalDetails.RegDate,
            "dateext"
          ),
        },
        {
          //Дата совершения платежа
          Key: i18n.t("main.operations.contractStatementData.CommitDate"),
          Value: this.$options.filters.date(
            this.modalDetails.OperationData.Date,
            "dateext"
          ),
        },
        {
          //Сумма
          Key: i18n.t("main.operations.contractStatementData.Sum"),
          Value: this.$options.filters.sum(
            this.modalDetails.OperationData.Sum,
            false,
            this.modalDetails.OperationData.Currency
          ),
        },
        {
          //Описание транзакции
          Key: i18n.t("main.operations.contractStatementData.Description"),
          Value: this.modalDetails.OperationData.Description,
        },
        {
          //Референс счёта"
          Key: i18n.t("main.operations.contractStatementData.Reference"),
          Value: this.modalDetails.OperationData.ReferenceId,
        },
        {
          //Наименование категории
          Key: i18n.t("main.operations.contractStatementData.MCCName"),
          Value: this.modalDetails.OperationData.Category,
        },
      ];
    }
  }

}
