























































































































































import { SubscriptionListVue } from "fs-pb-vue2/vueModels/subscription/SubscriptionListVue";
import SubscriptionEdit from "./SubscriptionEdit.vue";
import { Component } from "vue-property-decorator";
import i18n from "@/i18n";

@Component({
  components: {
    SubscriptionEdit
  }
})
export default class SubscriptionList extends SubscriptionListVue {
  mounted() {
    if (!this.configStore.apiData.ChargesEnable) {
      this.$router.go(-1);
    }
    if (this.$route.query.addNew == 'true')
      this.addItem();
  }
}
