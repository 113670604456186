































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ActionsList extends Vue {
  /**Признак загрузки */
  @Prop() loading!: boolean;
  /**Действия 
   * {
      text: '',
      iconClass: '',
      action: f()
    }
  */
  @Prop() actions!: Array<any>;

  async callAction(action: () => Promise<void>) {
    await action();
  }
}
