








































































































import { Component } from "vue-property-decorator";
import VerifyBox from "@/components/app/VerifyBox.vue";
import BackLink from "@/components/app/BackLink.vue";
import PhoneSelect from "../../components/operations/propeties/PhoneSelect.vue";
//import PhoneSelect from "@/components/operations/properties/PhoneSelect.vue";
import { RecoveryVue } from "fs-pb-vue2/vueModels/auth/RecoveryVue";
import EbsEsia from "@/components/auth/EbsEsia.vue";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: { VerifyBox, BackLink, EbsEsia, PhoneSelect }
})
export default class Recovery extends RecoveryVue {

  @Watch('menu', { immediate: true })
  menuChanged(val: boolean): void {
    const nowDate: string = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
    if (val && !this.OperationModel.BirthDate.length) {
      this.OperationModel.BirthDate = nowDate;
    } else if (!val) {
      if (this.$refs.menuField)
        this.$refs.menuField.validate();
    }
  }

  @Watch('OperationModel.BirthDate', { immediate: true })
  birthDateChanged(val: any): void {
    this.dateFormatted = this.formatDate(val);
  }  
  
  phoneBlur(): void {    
    this.$refs['recoveryPhone'].validate();
  }

  formatDate(date: any) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day.length < 2 ? '0' + day : day}.${month.length < 2 ? '0' + month : month}.${year}`
  }

  parseDate(date: any) {
    if (!date) return null

    const [day, month, year] = date.split('.')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  menu: boolean = false;

  dateFormatted: any = '';

  save(date: any) {
    this.$refs.menu.save(date)
  }

  async mounted() {
    await this.init();
  }
}
