








import { Component } from "vue-property-decorator";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";
import { getModule } from "vuex-module-decorators";
import Vue from "vue";

@Component({})
export default class EbsEsia extends Vue {

  /**
  * Хранилище настроек
  * @returns {ConfigStore}
  */
  get configStore(): ConfigStore {
    return getModule(ConfigStore, this.$store);
  }

  openEBS(): void {
    this.$router.push('/ebsInfo');
  }

}
