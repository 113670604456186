import { ContractsStore } from "fs-pb-vue2/store/modules/contracts";
import { PersonStore } from "fs-pb-vue2/store/modules/person";
import { AppStore } from "fs-pb-vue2/store/modules/app";
import { Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import IOperationsHistoryService from "fs-pb-vue2/services/history/IOperationsHistoryService";
import { RequestParamsModel } from "fs-pb-vue2/models/statement/requestParamsModel";
import { container } from "@/inversify.config";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import { CatalogVue } from "fs-pb-vue2/vueModels/catalog/CatalogVue";
import { ContractModel } from "fs-pb-vue2/models/contracts/contractModel";
import { CatalogData, CatalogsTypes, ContractsTypes } from "fs-pb-vue2/data";
import i18n from "@/i18n";
import analyze from 'rgbaster';

export class CatalogVueFS extends CatalogVue {

  get historyService(): IOperationsHistoryService {
    return container.get<IOperationsHistoryService>(DiTokens.OperationsHistoryService);
  }

  get contractId() {
    return this.$route.query["id"] ?? null;
  }

  get contract(): ContractModel | null {
    return this.contractId
      ? this.contractsStore.contracts.getContract(+this.contractId)
      : null;
  }

  requisitesDialog: boolean = false;

  get upToContractCatalog() {
    const ids: Array<CatalogData> = [];
    if (this.configStore.apiData.OperationCodeOfToContract) {
      const toContract: CatalogData = new CatalogData();
      toContract.CatalogType = CatalogsTypes.Operation;
      toContract.Id = parseInt(
        this.configStore.apiData.OperationCodeOfToContract
      );
      toContract.Caption = i18n.t("main.catalog.fromFinservAcc").toString();
      toContract.Picture = this.$options.filters.url("/Content/img/icons/finserv_icon.svg");
      if (this.activeContractsLength > 1)
        ids.push(toContract);
    }

    if (
      // this.contract && 
      // this.contract.Data.ContractType == ContractsTypes.CardAccount &&
      // (this.contract.Data.CurrencyCharCode == "RUR" || this.contract.Data.CurrencyCharCode == "RUB")
      // &&
      this.configStore.apiData.OperationCodeOfMe2MeOperation
      && this.contract &&
      this.contract.Data.ReferenceId == this.personStore.personInfo.Data.DefaultInAccount
    ) {
      const sbp: CatalogData = new CatalogData();
      sbp.CatalogType = CatalogsTypes.Operation;
      sbp.Id = parseInt(this.configStore.apiData.OperationCodeOfMe2MeOperation);
      sbp.Caption = i18n.t("main.catalog.fromOtherBankAcc").toString();
      sbp.Picture = this.$options.filters.url("/Content/img/sbp_logo.png");
      ids.push(sbp);
    }


    const keys: Array<string> = [];
    if (this.contract) {
      if (this.personStore.personInfo.Data.DefaultInAccount == this.contract.Data.ReferenceId) {
        if (this.contract.Data.ContractType == ContractsTypes.CardAccount) {
          keys.push(i18n.t("main.catalog.fromFinservAcc").toString());
          keys.push(i18n.t("main.catalog.fromOtherBankAcc").toString());
        } else if (!!this.contract.Data.ContractType && this.contract.Data.ContractType != ContractsTypes.CreditAccount) {
          keys.push(i18n.t("main.catalog.fromFinservAcc").toString());
        } else {
          keys.push(i18n.t("main.catalog.fromFinservAcc").toString());
        }
      } else {
        keys.push(i18n.t("main.catalog.fromFinservAcc").toString());
      }
    } 

    const res: Array<CatalogData> = [];

    keys.map((key: string) => {
      res.push(ids.find((id: CatalogData) => id.Caption == key))
    })

    const cardOperation: CatalogData = new CatalogData();
    cardOperation.CatalogType = CatalogsTypes.Operation;
    cardOperation.Id = parseInt(this.configStore.apiData.OperationCodeOfToOtherContractByCard);
    cardOperation.Caption = i18n.t("main.catalog.fromFinservAcc").toString();
    cardOperation.Picture = this.$options.filters.url("/Content/img/icons/finserv_icon.svg");

    return this.contract ? res : this.activeContractsLength > 1 ? [cardOperation] : [];
  }

  showAtm() {
    window.open("https://www.finsb.ru/about/offices/", "_blank");
  }

  showOtherBankCard() {
    window.open("https://pay.best2pay.net/webapi/P2PTransfer?sector=154", "_blank");
  }

  showDetails() {
    this.requisitesDialog = true;
  }

  /**Стор настроек */
  get contractsStore(): ContractsStore {
    return getModule(ContractsStore, this.$store);
  }

  /**Стор настроек */
  get personStore(): PersonStore {
    return getModule(PersonStore, this.$store);
  }

  /**Стор настроек */
  get appStore(): AppStore {
    return getModule(AppStore, this.$store);
  }

  async getOpeationsList() {

    const maxDate = new Date().toISOString().substr(0, 10);

    const resObj = await this.historyService.getOperationsHistory(
      new RequestParamsModel({
        dateBegin: maxDate.substring(0, 8) + "01",
        dateEnd: maxDate,
        contractId: '',
        searchText: '',
        sortType: 1,
        filter: 31,
        rootCatalog: this.catalogStore.catalog
      }), true
    );

    return resObj;
  }

  modalLoading: boolean = false;

  operInfoDialog: boolean = false;

  modalDetails: any = null;

  selectedColor: string = '';

  operationsList: Array<any> = [];

  get activeContractsLength(): number {
    const contracts: any = this.contractsStore.contracts.AllContracts;
    let result: number = 0;
    for (const key in contracts) {
      const contract: any = contracts[key];
      if (contract && !contract.Data.Hidden && contract.Data.ContractType !== 5 && contract.Data.CurrencyCharCode == 'RUR')
        result++;
    }
    return result;
  } 

  async showOperInfoDialog(operation: any) {
    this.modalLoading = true;
    this.operInfoDialog = true;
    this.modalDetails = await this.historyService.getOperationDetails(
      operation
    );
    // if (!this.modalDetails.OperationData.RequestState) {
    //   this.modalDetails.OperationData.RequestState = this.modalDetails.RequestData.requestState;
    //   this.modalDetails.OperationData.Sum = -1 * this.modalDetails.RequestData.values[this.modalDetails.RequestData.keys.indexOf('sum')];
    //   this.modalDetails.OperationData.IsRespondentActive = this.modalDetails.RequestData.isRespondentActive;
    //   const contractCur: string = this.contractsStore.contracts.getContractByRefId(this.modalDetails.RequestData.contractRef)?.Data.CurrencyCharCode;
    //   this.modalDetails.OperationData.Currency = this.$options.filters.currency(contractCur);
    // }
    if (operation.sbpDetails) {
      this.modalDetails.sbpDetails = operation.sbpDetails;
    }
    // this.fillStatementDetails();
    // Раскрасска хидера в цвет картинки
    try {
      let pict;
      if (!operation.Picture) {
        pict = require('./../../../public/img/default-oper.png');
      } else {
        pict = operation.Picture;
      }
      const result = await analyze(pict);
      this.selectedColor = result[0].color;
    } catch(e) {
      console.log(e);
      this.selectedColor = '';
    }    
    this.modalLoading = false;
  }

  async initOperationModal() {
    if (this.$route.query.operId && this.appStore.showCatalogOperationModal) {
      if (!this.appStore.loading)
        this.appStore.setLoading(true);
      this.operationsList = await this.getOpeationsList();
      if (this.operationsList && this.operationsList.length > 0) {
        const operation: any = this.operationsList.find((oper: any) => oper.Id == this.$route.query.operId);
        this.showOperInfoDialog(operation ? operation : { Id: this.$route.query.operId, ReferenceId: '' });
      }
      this.appStore.setLoading(false);
      this.appStore.setCatalogFlag(false);
    }
  }

}