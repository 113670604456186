


















































































































































































































































































































































































import { OperationModalVueFS } from "@/extensions/history/OperationModalVueFS";
import { Component } from "vue-property-decorator";
import FsTemplateDialog from "@/components/templates/FsTemplateDialog.vue";

@Component({
  components: { FsTemplateDialog }
})
export default class OperationModal extends OperationModalVueFS {

  // async mounted() {
  //   await this.init();
  // }

  get sumValue(): string {
    return this.$options.filters.sum(
      this.operationData.Sum, 
      this.isSucessOper(this.operationData.RequestState) || this.details.RequestData.respondentCode == this.configStore.apiData.OperationCodeOfMe2MeOperation, 
      this.operationData.Currency
    );
  }
}
