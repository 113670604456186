
































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DetailsList from "@/components/app/DetailsList.vue";
import ActionsList from "@/components/app/ActionsList.vue";
import OperationsList from "@/components/history/OperationsList.vue";
import {CardsStates} from "fs-pb-vue2/data/enums/cardsStates";
import { StatementModel } from "fs-pb-vue2/models/statement/statementModel";

@Component({
  components: { DetailsList, ActionsList, OperationsList }
})
/**Нижний блок информации по счету */
export default class ContractDetails extends Vue {
  /**Признак загрузки */
  @Prop() loading!: boolean;
  /**Детали 
   * {
      disabled: true,
      name: '',
      textVal: '',
      isAction: true,
      model: object,
      action: f()
    }
  */
  @Prop() details!: Array<any>;

  @Prop() card!: any;

  @Prop() contract!: any;

  /**Действия 
   * {
      text: '',
      iconClass: '',
      action: f()
    }
  */
  @Prop() actions!: Array<any>;
  @Prop() detailsTitle!: Array<any>;

  @Prop() statementModel!: StatementModel;

get isCard(): boolean {
  return !!this.card;
}

  @Watch("$route", { immediate: true })
  changed(to, from) {
    this.$forceUpdate();
    if (this.card && this.card.Data.CardsState === CardsStates.Issued)
      this.detailsActiveHref = 'about';
    else
      this.detailsActiveHref = 'events';
  }

  @Watch('contract', { immediate: true })
  newContractComes(val: any) {
    this.$forceUpdate();
    this.updateKey++;
  }

  // getLoading: boolean = true
  //   return this.loading;
  // }
  /**Активный таб*/
  detailsActiveHref: string = "events";

  updateKey: number = 0;

  get windowWidth(): number {
    return window.innerWidth;
  }

  get hideOperationsFilter(): boolean {
    if (this.contract && 
       (this.contract.Data.ContractType === 1 || this.contract.Data.ContractType === 5 || this.contract.Data.ContractType === 2)) {
      return true;
    }
    return false;
  }
}
