












import BackLink from "@/components/app/BackLink.vue";
import { OperationsHistoryVue } from "fs-pb-vue2/vueModels/history/OperationsHistoryVue";
import OperationsList from "@/components/history/OperationsList.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: { OperationsList, BackLink }
})
export default class OperationsHistory extends OperationsHistoryVue {}
