

























import { DatePickerVue } from "fs-pb-vue2/vueModels/app/datePicker/DatePickerVue";
import { Component, Prop, Vue } from "vue-property-decorator";
import DayPicker from "./pickers/DayPicker.vue";
import MonthPicker from "./pickers/MonthPicker.vue";

@Component({
  components: { DayPicker, MonthPicker }
})
export default class DatePicker extends DatePickerVue {}
