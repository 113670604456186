

























































































































































































































import { LoginVue } from "fs-pb-vue2/vueModels/auth/LoginVue";
import { Component } from "vue-property-decorator";
import BackLink from "@/components/app/BackLink.vue";
import { FinServLoginModel } from "@/extensions/models/auth/FinServLoginModel";
import EbsEsia from "@/components/auth/EbsEsia.vue";
import { getCookie } from "fs-pb-vue2/utilities/cookieHelper";
import IAuthService from "fs-pb-vue2/services/auth/IAuthService";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import { container } from "@/inversify.config";

@Component({
  components: { BackLink, EbsEsia },
})
export default class Login extends LoginVue {
  /**Модель авторизации */
  public model: FinServLoginModel = new FinServLoginModel();

  /**Ссылка на службу авторизации */
  get authService(): IAuthService<FinServLoginModel> {
    return container.get<IAuthService<FinServLoginModel>>(DiTokens.AuthService);
  }

  async mounted() {
    // if (!this.isOldClient) this.$router.push({path: '/register', query: this.$route.query});
    await this.init();
  }
  get isOldClient(): boolean {
    return getCookie("isOldClient") == "true";
  }
  validateForm(): void {
    // Проверяем форму на валидность
    (this.$refs.form as any).validate();
  }

  resetFormValidation(): void {
    // Сбрасываем реззультаты валидации
    (this.$refs.form as any).resetValidation();
  }
}
