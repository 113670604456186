















































































































































import Vue from "vue";
import BackLink from "@/components/app/BackLink.vue";
import { ChargesVue } from "fs-pb-vue2/vueModels/charges/ChargesVue";
import i18n from "@/i18n";
import { Component } from "vue-property-decorator";

@Component({
  components: { BackLink }
})
export default class Charges extends ChargesVue {

  catImages: any[] = [];

  async mounted() {
    if (!this.configStore.apiData.ChargesEnable) {
      this.$router.go(-1);
    }
    if (this.catImages.length === 0) {
      await this.getCategorysImages();
    }
    await this.init();
  }

  getCategoryImage(category: string): string | undefined {
    const categoryImage = this.catImages.find(cat => cat.category === category);
    return categoryImage ? categoryImage.image : undefined;
  }
}
