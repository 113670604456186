


















































































































































































import { MainNavigationVueMK } from "@/extensions/navigation/MainNavigationVueMK";
import { Component } from "vue-property-decorator";
import ContractsPanel from "@/components/contracts-panel/ContractsPanel.vue";
// import { MainNavigationVue } from "fs-pb-vue2/vueModels/navigation/MainNavigationVue";

@Component({
  components: { ContractsPanel }
})
export default class MainNavigation extends MainNavigationVueMK {}
