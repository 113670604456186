





























































































































import { Component } from "vue-property-decorator";
import OperationProperties from "@/components/operations/OperationProperties.vue";
import BackLink from "@/components/app/BackLink.vue";
import DetailsList from "@/components/app/DetailsList.vue";
import VerifyBox from "@/components/app/VerifyBox.vue";
import { CreditOperationVue } from "fs-pb-vue2/vueModels/operations/CreditOperationVue";
import i18n from "@/i18n";

@Component({
  components: {
    OperationProperties,
    VerifyBox,
    BackLink
  },
})
export default class CreditOperation extends CreditOperationVue {}
