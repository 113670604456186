













import BackLink from "@/components/app/BackLink.vue";
import { StatementVue } from "fs-pb-vue2/vueModels/history/StatementVue";
import OperationsList from "@/components/history/OperationsList.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: { OperationsList, BackLink }
})
export default class Statement extends StatementVue {}
