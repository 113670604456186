import i18n from "@/i18n";
import { LoginModel } from "fs-pb-vue2/models/auth/LoginModel";

/** Модель стандартной авторизации финсервиса */
export class FinServLoginModel extends LoginModel {
 /** Логин */
 public Alias: string = "";

 /**Номер карты */
 public Pan: string = "";

 /**Номер карты */
 public PanMask: string = "#### #### #### ####";

 /** Тип логина  pan/login*/
 public LoginType: string = "";

 /**Правила заполнения логина */
 public AliasRules: Array<any> = [
   (l: any) => !!l || i18n.t("auth.errors.loginEmpty"),
   (l: any) => (l && l.length <= 200) || i18n.t("auth.errors.loginMaxLength")
 ];

 /**Правила заполнения номера карты */
 public PanRules: Array<any> = [
   (l: any) => !!l || i18n.t("auth.errors.panEmpty"),
   (l: any) => (l && l.length <= 200) || i18n.t("auth.errors.panMaxLength")
 ];

 public RememberMe: boolean = false;
}
