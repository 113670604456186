













































































































































































































































import { OperationsListVueFS } from "@/extensions/history/OperationsListVueFS";
import OperationBlock from "@/components/history/OperationBlock.vue";
import OperationModal from "@/components/history/OperationModal.vue";
import OperationsFilter from "@/components/history/OperationsFilter.vue";
import DatePicker from "@/components/picker/DatePicker.vue";
import MyChart from "fs-pb-vue2/components/MyChart.vue";
import SearchInput from "fs-pb-vue2/components/SearchInput.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    OperationBlock,
    DatePicker,
    SearchInput,
    OperationModal,
    OperationsFilter,
    MyChart
  }
})
export default class OperationsList extends OperationsListVueFS {
  @Prop() hideTime!: boolean;
  @Prop() isCreditShedule!: boolean;

  async mounted() {
    await this.init();
  }

  showOperInfoDialog(operation: any):void {
    if(!this.isCreditShedule)
      super.showOperInfoDialog(operation)
  }
}
