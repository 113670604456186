











































































































































































































































import { Component } from "vue-property-decorator";
import OperationProperties from "@/components/operations/OperationProperties.vue";
import BackLink from "@/components/app/BackLink.vue";
import DetailsList from "@/components/app/DetailsList.vue";
import VerifyBox from "@/components/app/VerifyBox.vue";
import { CatalogOperationVue } from "fs-pb-vue2/vueModels/operations/CatalogOperationVue";
import SbpEnableModal from "@/components/settings/SbpEnableModal.vue";
import AccountInDefaultModal from "@/components/settings/AccountInDefaultModal.vue";
// import FsTemplateDialog from "@/components/templates/FsTemplateDialog.vue";
import i18n from "@/i18n";

@Component({
  components: {
    OperationProperties,
    DetailsList,
    VerifyBox,
    BackLink,
    SbpEnableModal,
    // FsTemplateDialog,
    AccountInDefaultModal
  },
})
export default class CatalogOperation extends CatalogOperationVue {

  async nextGMP(state: number): Promise<void> {
    this.OperationModel.PayReplaceGmpState = state;
    await this.nextStep();
  }

}
