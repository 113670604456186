


























import OperationModal from "@/components/history/OperationModal.vue";
import Vue from "vue";
import Slider from "@/components/app/Slider.vue";
import { Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { CatalogVueFS } from "@/extensions/catalog/CatalogVueFS";
import { AppStore } from "fs-pb-vue2/store/modules/app";
import BackLink from "@/components/app/BackLink.vue";

@Component({
  components: { BackLink, OperationModal },
})
export default class Products extends CatalogVueFS {
  async mounted() {
    await this.initOperationModal();
  }

  get products(): Array<any> {
    return this.configStore.products;
  }

  goToAnotherPage(item: any) {
    if (item.IsOperation)
      this.$router.push(`/operation/0/${item.CatalogId}`);
    else
      window.open(item.Url, '_blank');
  }

}
