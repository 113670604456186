





























import { Component } from "vue-property-decorator";
import { AppErrorVue } from "fs-pb-vue2/vueModels/app/AppErrorVue";

@Component({})
export default class AppError extends AppErrorVue {}
