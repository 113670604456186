






































import { DayPickerVue } from "fs-pb-vue2/vueModels/app/datePicker/DayPickerVue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DayPicker extends DayPickerVue {}
