import { AuthLayoutVue } from "fs-pb-vue2/vueModels/layouts/AuthLayoutVue";
import { getCookie } from "fs-pb-vue2/utilities/cookieHelper";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import { container } from "@/inversify.config";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import { AuthApi } from "fs-pb-vue2/services/api/AuthApi";
import i18n from "@/i18n";

/**Модель представления авторизации */
export class AuthLayoutVueFS extends AuthLayoutVue {

  ebsLoading: boolean = false;

  dataGetAttempts: number = 0;

  /**
  * Сервис уведомлений
  * @returns {IAlertService}
  */
  get alertService(): IAlertService {
    return container.get<IAlertService>(DiTokens.AlertService);
  }

  async init() {
    await super.init();
    const sid: string | string[] = this.$route.query && this.$route.query['sid'] ? this.$route.query['sid'] : '';
    const resSecret: string | string[] = this.$route.query && this.$route.query['res_secret'] ? this.$route.query['res_secret'] : '';
    if ((sid && sid.length > 0) || (resSecret && resSecret.length > 0)) {
      this.ebsLoading = true;
      try {
        const res: any = await AuthApi.getSIDInformation(sid.toString(), resSecret.toString());
        if (res && res.result === 'ok') {
          this.alertService.success(i18n.t('common.ebsSuccess').toString(), false);
        }
        this.ebsLoading = false;
      } catch (e: any) {
          this.dataGetAttempts++;
        if (this.dataGetAttempts < 3) {
          setTimeout(async () => { await this.init(); }, 5000)
        } else {
          this.alertService.error(e.message, false);
          this.ebsLoading = false;
          this.dataGetAttempts = 0;
        }
      }
    }
    if (getCookie('isOldClient') != 'true') {
      this.$router.push({path: '/register', query: this.$route.query});
    }
  }

}
//угу, сейчас залью, после мерджа 