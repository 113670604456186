






import { Component } from "vue-property-decorator";
import ListProperty from "./ListProperty.vue";
import { BaseOperationPropertyVue } from "fs-pb-vue2/vueModels/operations/properies/BaseOperationPropertyVue";

@Component({
  components: { ListProperty }
})
export default class UserAccountProperty extends BaseOperationPropertyVue {}
