import i18n from "@/i18n";
import { PersonInfoData } from "fs-pb-vue2/data";
import { MainNavigationVue } from "fs-pb-vue2/vueModels/navigation/MainNavigationVue";
import { Watch } from "vue-property-decorator";

export class MainNavigationVueMK extends MainNavigationVue {

  @Watch('configStore.apiData.CashbackEnable', { immediate: true })
  apiComes(val: boolean): void {
    if (val && !this.links.find((it: any) => it.url === '/cashback')) {
      this.links.push({ title: this.$i18n.t("main.header.links.cashback"), url: "/cashback" });
    }
  }

  @Watch('personStore.personInfo.Data', { immediate: true })
  personInfoData(val: PersonInfoData): void {
    if (val && val.Mode === 1 && !this.links.find((it: any) => it.url === '/cashback')) {
      this.links.push({ title: this.$i18n.t("main.header.links.cashback"), url: "/cashback" });
    }
  }

  /** Список ссылок в меню */
  links: Array<any> = [
    { title: this.$i18n.t("main.header.links.main"), url: "/", exact: true },
    { title: this.$i18n.t("main.header.links.payments"), url: "/payments" },
    { title: this.$i18n.t("main.header.links.settings"), url: "/settings" },
  ];

  /** Меняет признак видимости левого меню */
  get hideShowSidebar(): () => void {
    return () => {
      this.isLeftSideOpen = !this.isLeftSideOpen;
    };
  }
}
