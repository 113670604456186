import i18n from "@/i18n";
import cardState from "fs-pb-vue2/filters/cardState.filter";
import { CardVue } from "fs-pb-vue2/vueModels/contracts/CardVue";
import { CardModel } from "fs-pb-vue2/models/contracts/cardModel";

/**Модель представления авторизации */
export class CardVueFS extends CardVue {

  goToAnotherContract(next: boolean): void {
    this.myCards.map((item, i, arr) => {
      if (item === this.card) {
        if (next === true) {
          if (i !== arr.length - 1)
            this.$router.push(`/card/${this.contract.Data.Id}/${arr[i+1].Data.Id}`);
          else
            this.$router.push(`/card/${this.contract.Data.Id}/${arr[0].Data.Id}`);
        } else {
          if (i !== 0)
            this.$router.push(`/card/${this.contract.Data.Id}/${arr[i-1].Data.Id}`);
          else
            this.$router.push(`/card/${this.contract.Data.Id}/${arr[arr.length-1].Data.Id}`);
        }
      }
    });
  }

  get isHiddenItemsVissible(): boolean {
    return this.configStore.isHiddenItemsVissible;
  }

  get myCards(): Array<CardModel> {
    return this.contract.Cards.filter((card: CardModel) => 
      !card.Data.ExpiredLongTime && ((!card.Data.Hidden && card.Data.CardState !== 11 && card.Data.CardState !== 2) || this.isHiddenItemsVissible)
    );
  }

/**Список доступных операций */
  get actions(): Array<any> {
    const actions: Array<any> = [];
    if (this.contract && this.card) {
      if (this.card.IsActive) {
        if (
          this.contract.IsOpen &&
          this.contract.Data.CanDeposit &&
          this.configStore.apiData.OperationCodeOfToContract
        ) {
          /**Действие пополнения счета */
          actions.push({
            text: i18n.t("main.contracts.actions.fill"),
            iconClass: "fill-card-icon",
            action: () => {
              this.$router.push(
                `/contractUpCatalog?card=${this.card.Data.ReferenceId}`
              );
            }
          });
        }
        if (this.contract.IsOpen && this.contract.Data.CanWithdraw) {
          actions.push({
            /**Действие оплаты со счета */
            text: i18n.t("main.cards.actions.pay"),
            iconClass: "pay-icon",
            action: () => {
              this.$router.push(
                `/payments?contract=${this.contract.Data.ReferenceId}`
              );
            }
          });
        }
        if (!this.card.IsIssued) {
          /**Действие запроса выписки по счету*/
          actions.push({
            text: i18n.t("main.cards.actions.statement"),
            iconClass: "statement-icon",
            action: () => {
              // this.statementMode = true;
              this.$router.push(
                `/statement/${this.contract.Data.Id}/${this.card.Data.Id}`
              );
            }
          });
        }
        if (
          this.configStore.apiData.OperationCodeOfCardPinCode &&
          this.configStore.apiData.OperationCodeOfCardPinCode !== ""
        ) {
          // /**Сменить пин код */
          actions.push({
            text: i18n.t("main.cards.actions.changePin"),
            iconClass: "change-pin-icon",
            action: () => {
              this.dialogContent = new Object({
                vueName: "CardPinModal",
                params: { card: this.card }
              });
              this.dialog = true;
            }
          });
        }
        /**Блокировка карты */
        actions.push({
          text: i18n.t("main.cards.actions.blockCard"),
          iconClass: "block-card-icon",
          action: () => {
            this.dialogContent = new Object({
              vueName: "CardBlockModal",
              params: {
                isBlock: true,
                contract: this.contract,
                card: this.card
              }
            });
            this.dialog = true;
          }
        });
        // if (this.configStore.apiData.OperationCodeOfOpenCard) {
        //   actions.push({
        //     text: i18n.t("main.cards.actions.orderCard"),
        //     iconClass: "open-deposit-icon",
        //     action: () => {
        //       this.$router.push(
        //         `/operation/0/${this.configStore.apiData.OperationCodeOfOpenCard}`
        //       );
        //     }
        //   });          
        // }

        // /**Установить лимиты */
        // actions.push({
        //   text: i18n.t("main.cards.actions.setLimits"),
        //   iconClass: "set-limits-icon",
        //   action: () => {
        //     console.log("Установить лимиты");
        //   },
        // });
      } else if (this.card.IsBlocked) {
        /**Блокировка карты */
        actions.push({
          text: i18n.t("main.cards.actions.unblockCard"),
          iconClass: "block-card-icon",
          action: () => {
            this.dialogContent = new Object({
              vueName: "CardBlockModal",
              params: {
                isBlock: false,
                contract: this.contract,
                card: this.card
              }
            });
            this.dialog = true;
          }
        });
      }
      // actions.push({
      //   actionName: 'tarif',
      //   text: i18n.t("main.contracts.actions.tarif"),
      //   iconClass: "statement-icon",
      //   action: () => {
      //     window.open(`https://www.finsb.ru/individuals/everyday/bank-counts/tariffs.pdf`, '_blank');
      //   }
      // });
    }
    return actions;
  }

  /**Список информации */
  get details(): Array<any> {
    let details: Array<any> = [];
    if (this.contract && this.card) {
      details = [
        {
          disabled: this.setVisibilityLoading,
          name: i18n.t("main.contracts.details.show"),
          textVal: (isVisible: boolean) => { 
            return isVisible
            ? i18n.t("main.contracts.details.showContract")
            : i18n.t("main.contracts.details.hideContract")
          },
          isAction: true,
          model: !this.card.Data.Hidden,
          action: async () => {
            await this.setVisibility();
          }
        }
      ];
      const cardDetails: Array<any> = [
        {
          //Номер карты
          name: i18n.t("main.cards.details.cardNumber"),
          textVal: this.$options.filters.cardNumberFilter(this.card.Data.ReferenceId, false)
        },
        {
          //имя пользователя
          name: i18n.t("main.contracts.details.cardFIO"),
          textVal: this.personStore.personInfo.FIO
        },
        // {
        //   //Тип карты
        //   name: i18n.t("main.cards.details.type"),
        //   textVal: this.card.CardPlasticTypeTitle
        // },
        // {
        //   //Номер договора
        //   name: i18n.t("main.cards.details.contractNum"),
        //   textVal: this.contract.Data.Number //ContractNumber
        // },
        {
          //Состояние
          name: "Состояние",
          textVal: cardState(this.card.Data.CardState)
        },
        {
          //Срок действия
          name: i18n.t("main.cards.details.expDate"),
          textVal: this.card.Data.ExpDate
        },
        {
          //Валюта счета
          name: i18n.t("main.contracts.details.currencyCard"),
          textVal: this.contract.Currency
        },
        {
          //Основной счет
          name: i18n.t("main.cards.details.mainAccount"),
          isLink: true,
          textVal: this.contract.Data.Alias,
          link: `/contract/${this.contract.Data.Id}`
        },
        // {
        //   //Номер основного счета
        //   name: i18n.t("main.cards.details.mainAccountNumber"),
        //   textVal: this.contract.Data.MainAccount
        // },
        {
          //Доступный остаток на счете
          name: i18n.t("main.cards.details.acntDisposableLimit"),
          textVal: `${this.contract.DisposableLimit} ${this.contract.Currency}`
        }
      ];
      details.push(...cardDetails);
      if (this.card.Data.SmsService) {
        details.push({
          name: i18n.t("main.cards.details.smsService"),
          textVal: this.card.Data.SmsService
        });
      } else if (this.card.Data.ViberService) {
        details.push({
          name: i18n.t("main.cards.details.viberService"),
          textVal: this.card.Data.ViberService
        });
      }
    }
    return details;
  }
}