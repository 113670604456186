









































import Vue from "vue";
import i18n from "@/i18n";
import { Component } from "vue-property-decorator";
import { getCookie, setCookie } from "fs-pb-vue2/utilities/cookieHelper";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import { getModule } from "vuex-module-decorators";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";
import { container } from "@/inversify.config";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import { AuthApi } from "fs-pb-vue2/services/api/AuthApi";

@Component({
  // components: { EbsEsia }
})
export default class EbsInfo extends Vue {

  /**
  * Сервис уведомлений
  * @returns {IAlertService}
  */
  get alertService(): IAlertService {
    return container.get<IAlertService>(DiTokens.AlertService);
  }

  get configStore(): ConfigStore {
    return getModule(ConfigStore, this.$store);
  }

  infoArr: Array<string> = ['first', 'second', 'third', 'fourth', 'fifth'];

  esiaLoading: boolean = false;

  async initEsiaRegistration(): void {
    this.esiaLoading = true;

    const browserList: Array<string> = ['MSIE', 'NET CLR', 'SputnikBrowser', 'YaBrowser'];

    const isBrowserAgree: boolean = browserList.filter((browser: string) => navigator.userAgent.search(browser) > 0).length > 0;


    if (!isBrowserAgree) {
      this.alertService.warning(i18n.t('auth.form.ebsInfo.messages.broswerNotAgry'), false);
      this.esiaLoading = false;
      return;
    }
    try {
      const response: any = await AuthApi.getEbsRegistrationLink();
      
      if (response && response.data && response.data.link) {
        window.open(response.data.link);
      }

      this.esiaLoading = false;

    } catch (e: any) {
      this.alertService.error(e.message);
      this.esiaLoading = false;
    }

  }

}
