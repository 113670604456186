








































































































import { AuthLayoutVueFS } from "@/extensions/layouts/AuthLayoutVueFS";
import { Component } from "vue-property-decorator";
import CommonFooter from "@/components/navigation/CommonFooter.vue";
import AuthNavigation from "@/components/navigation/AuthNavigation.vue";
import { getCookie } from "fs-pb-vue2/utilities/cookieHelper";
import AppError from "@/components/app/AppError.vue";

@Component({
  components: { CommonFooter, AuthNavigation, AppError }
})
export default class AuthLayout extends AuthLayoutVueFS {

  async created() {
    await this.init();
  }

}
