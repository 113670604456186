











































































































































import { Component } from "vue-property-decorator";
import { BaseOperationPropertyVue } from "fs-pb-vue2/vueModels/operations/properies/BaseOperationPropertyVue";
import SearchSelect from "./SearchSelect.vue";

@Component({
  components: {
    SearchSelect
  }
})
export default class BankBikProperty extends BaseOperationPropertyVue {}
