













































































import { remove } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { shallowMount } from "@vue/test-utils";
import { getElement } from "@amcharts/amcharts4/core";
import i18n from "@/i18n";
import Vue from "vue";
import { DeclarationPropertyValueData } from "../data/declarationPropertyValueData";

/**Кнопка назад */
export default Vue.extend({
  name: "cardSelector",
  props: {
    /** внешнее значение v-model */
    value: {
      required: true,
    },
    cards: Array,
    /** название инпута */
    label: String,
    /** id инпута */
    id: String,
    /** цвет инпута */
    color: String,
    /** индикатор загрузки инпута */
    loading: Boolean,
    /** индикатор изменяемости инпута */
    readonly: Boolean,
    /** высота инпута */
    height: String,
    /** Правила при которых выводится ошибка */
    rules: {
      type: Array,
      required: false,
    },
    default: String
  },
  data: () => ({
    /** Позиция курсора */
    curPos: 0,

    CardValue: "",
        /** внутреннее значение v-model */
    FullCardValue: "",
    showCards: false,
    privateValue: '',
    mask: "#### #### #### ####",
    showMyNumberCard: true,
    error: false,
    errorText: "",
    defRules: [
      (p) =>
        (p !== null && p.substr(0, p.length - 2) !== "") ||
        i18n.t("main.operation.errors.sumError"),
    ],
  }),
  /** при создании присваеваем внешнее значние v-model внутреннему */
  created() {
    if (this.default) {
      this.selectCard(this.default);
    } else {
      this.CardValue = this.value ? this.value.includes('|') ? this.value.split('|')[1] : this.value : "";
      this.FullCardValue = this.value;
    }
    if (this.rules !== undefined && this.rules !== null && this.rules !== "")
      this.defRules = this.rules;
  },
  watch: {
    /** Отслеживаем изменение внешнего v-model из вне */
    value: {
      deep: true,
      handler(val) {
        if (val == this.CardValue || val == this.FullCardValue)
          return;
        this.selectCard(val && val.Key ? val.Key : "");
          // val && this.cards.find((card: any) => card.Key == val) ? val :
          // val.replace(/\s/g, ''));
      },
    }
    // /** Отслеживаем изменение внутреннего v-model */
    // CardValue: {
    //   deep: true,
    //   handler(val) {
    //     if (this.privateValue.length > 0)
    //       this.CardValue = this.privateValue.replace(/\s/g, '');
    //   },
    // },
  },
  computed: {
    isMyNumberCard(): boolean {
      if (this.selectedCard) {
        return true;
      }
      return false;
    },
    selectedCard(): any {
      return this.CardValue ? this.cards.find((card: any) => card.Key.indexOf(this.CardValue.split(' ').join('')) >= 0 )?.Value : "";
    },
    selectedCardAttribute(): any {
      return this.CardValue ? this.cards.find((card: any) => card.Key.indexOf(this.CardValue.split(' ').join('')) >= 0 )?.attribute : "";
    }
  },
  /** Приводим строку к нужному формату после загрузки */
  methods: {
    /** Проверяем правила и вызываем событие блюр */
    checkRulesForReq() {
      this.$refs[this.id]?.validate();
      this.$emit('blur');
      // } else {
      //   this.error = true;
      //   this.errorText = checkResult;
      // }
    },
    activateMyInput(): void {
      this.$emit('focus');
      this.showMyNumberCard = false;
      this.$refs[this.id].focus();
    },
    toggleMyCards() {
      const a = document.getElementsByClassName("card-selector--arrow")[0];

      if (a) {
        if (!this.showCards) {
          a.classList.add("rotated");
        } else {
          a.classList.remove("rotated");
        }
      }
      this.showCards = !this.showCards;
    },
    hideMyCards() {
      const a = document.getElementsByClassName("card-selector--arrow")[0];
      if(a)
        a.classList.remove("rotated");
      this.showCards = false;
    },
    selectCard(value: any) {
      if (value) {
        const val =  value.indexOf('|') >= 0 ? value.split('|')[1] : value;
        const selectedVal = this.cards.find((card: any) => card.Key.indexOf(val) >= 0 )?.Key;

        this.CardValue = val;
        this.FullCardValue = selectedVal ?? value;
        this.privateValue = `${val.substr(0, 4)} ${val.substr(
          4,
          4
        )} ${val.substr(8, 4)} ${val.substr(12, 4)}`;
      } else {
        this.CardValue = '';
        this.FullCardValue = '';
        this.privateValue = '';
      }
      this.$emit("input", this.FullCardValue ?? "");
      this.checkRulesForReq();
      this.hideMyCards();
    },
    hideMyCardsHandler(e: Event) {
      if (
        e.target != document.getElementById(this.id) &&
        e.target !=
          document.getElementsByClassName("card-selector--arrow")[0] && e.target != document.getElementsByClassName("card-selector--arrow")[1] &&
        this.showCards
      )
        this.hideMyCards();
    },
  },
});
