import { ContractBlockVue } from "fs-pb-vue2/vueModels/contracts-panel/ContractBlockVue";
import i18n from "@/i18n";
import analyze from 'rgbaster';

export class ContractBlockVueFS extends ContractBlockVue {
	getColor(card: any): string {
		try {
			return analyze(`${card.CardImageUrl}.png`).then((res: any) => {
				let luminance: number = 0;
				for (let i = 0; i < 4; i++) {
					const rgb = res[i].color.substring(4,res[i].color.length-1).split(',');
					const color = {
						R: rgb[0],
						G: rgb[1],
						B: rgb[2]
					}
					const lumi = (0.2126  * color.R + 0.7152 * color.G + 0.0722 * color.B)/255;
					luminance += lumi;		
				}

				luminance = luminance / 4;

				let result;
				if (luminance > 0.75)
					result = '#000';
				else 
					result = '#fff';
				card.Data.cardNumberColor = result;
			});
		} catch (e) {
			console.log(e);
		} 
	}
}