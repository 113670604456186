
















































































































































































import { FsTemplateDialogVue } from "fs-pb-vue2/vueModels/templates/FsTemplateDialogVue";
import { Component } from "vue-property-decorator";
import VerifyBox from "@/components/app/VerifyBox.vue";
import OperationProperties from "@/components/operations/OperationProperties.vue";
import DetailsList from "@/components/app/DetailsList.vue";

@Component({
  components: { VerifyBox, OperationProperties, DetailsList }
})
export default class FsTemplateDialog extends FsTemplateDialogVue {}
