






















































































import { getRandInt } from "fs-pb-vue2/utilities/index";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class DetailsList extends Vue {
  /** Признак скрывания пустых полей */
  @Prop({ default: false }) hideEmpty!: boolean;
  /**Признак загрузки */
  @Prop() loading!: boolean;

  @Prop() withoutPadding!: boolean;
  // /**Минимальная длина строк прилодера */
  // @Prop() preloaderMin!: number;
  // /**Максимальная длина строк прилодера */
  // @Prop() preloaderMax!: number;
  /**Детали 
   * {
      disabled: true,
      name: '',
      textVal: '',
      isAction: true,
      model: object,
      action: f()
    }
  */
  @Prop() details!: Array<any>;

  async callAction(detail: any) {
    await detail.action();
  }

  getRandWidth() {
    return getRandInt(30, 100);
  }
}
