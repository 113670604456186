






































































































import { remove } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { shallowMount } from "@vue/test-utils";
import { getElement } from "@amcharts/amcharts4/core";
import i18n from "@/i18n";
import Vue from "vue";
import { DeclarationPropertyValueData } from "../data/declarationPropertyValueData";

/**Кнопка назад */
export default Vue.extend({
  name: "PhoneSelect",
  props: {
    withImage: Boolean,
    /** внешнее значение v-model */
    value: {
      required: true,
      type: null,
    },
    options: Array,
    /** название инпута */
    label: String,
    /** id инпута */
    id: String,
    /** цвет инпута */
    color: String,
    /** индикатор загрузки инпута */
    loading: Boolean,
    /** индикатор изменяемости инпута */
    readonly: Boolean,
    /** высота инпута */
    height: String,
    /** Правила при которых выводится ошибка */
    rules: {
      type: Array,
      required: false,
    },
    isBanks: Boolean,
    width: Number,
    declName: String
  },
  data: () => ({
    /** Позиция курсора */
    curPos: 0,
    /** внутреннее значение v-model */
    DisplayValue: '',
    privateValue: null,
    showCards: false,
    error: false,
    searchValue: '',
    errorText: "",
    arrayIndex: 10,
  }),
  /** при создании присваеваем внешнее значние v-model внутреннему */
  created() {
    this.privateValue = this.value;
    this.DisplayValue = this.value ? this.value.Value ? this.value.Value : this.value : '';
  },
  computed: {
    vissibleOptions() {
      if (this.options && this.options.length > 0) {
        if (this.searchValue.length > 0) {
          const res: Array<any> = this.options.filter((card: any) => card.Caption.toLowerCase().includes(this.searchValue.toLowerCase()) || card.DisplayCode.toLowerCase().includes(this.searchValue.toLowerCase()));
          return res;
        }
        return this.options;
      }
      return [];
    },
    listDOM() {
      return document.getElementById('list-content');
    },
    listScrollPoss(): number {
      if (this.listDOM)
        return this.listDOM.scrollTop;
      return 0;
    }

  },
  watch: {
    /** Отслеживаем изменение внешнего v-model из вне */
    value: {
      deep: true,
      handler(val: any) {
        this.DisplayValue = val && val.Value ? val.Value : val ? val : '';
        this.privateValue = val;
      },
    },
    /** Отслеживаем изменение внутреннего v-model */
    DisplayValue: {
      deep: true,
      handler(val: any) {
        if (val) {
          this.$emit("input", this.privateValue);
        }
      },
    },
    showCards: {
      deep: true,
      handler(val: boolean) {
        if (val)
          setTimeout(() => {
            document.getElementById('mySearchInput').focus()
          }, 0);
        else {
          this.checkRulesForReq();
          this.$emit('blur');
        }
      }
    }
  },
  /** Приводим строку к нужному формату после загрузки */
  methods: {
    handleScroll(e: any) {8
      if (document.getElementById('list-content').scrollTop === document.getElementById('list-content').scrollHeight - 200) {
        this.arrayIndex += 10;
      }
    },
    /** Проверяем правила и вызываем событие блюр */
    checkRulesForReq() {
      this.$refs[this.id].validate();
      // const checkResult: boolean = this.id
      //   ? this.$refs[this.id].validate()
      //   : true;
      // if (checkResult === true) {
      //   this.error = false;
      //this.$emit('blur');
      // } else {
      //   this.error = true;
      //   this.errorText = checkResult;
      // }
    },
    toggleMyCards() {
      const a = document.getElementsByClassName("card-selector--arrow")[0];

      if (a) {
        if (!this.showCards) {
          a.classList.add("rotated");
        } else {
          a.classList.remove("rotated");
        }
      }
      this.showCards = !this.showCards;

      this.arrayIndex = 10;
    },
    hideMyCards() {
      const a = document.getElementsByClassName("card-selector--arrow")[0];
      if(a)
        a.classList.remove("rotated");
      this.showCards = false;
    },
    selectCard(value: any) {
      this.privateValue = value;
      this.DisplayValue = value.Value ?? value;
      this.$emit("input", this.privateValue);
      this.checkRulesForReq();
      this.hideMyCards();
    },
    hideMyCardsHandler(e: Event) {
      if (
        e.target != document.getElementById(this.id) &&
        e.target !=
          document.getElementsByClassName("card-selector--arrow")[0] &&
        this.showCards
      )
        this.hideMyCards();
    },
  },
});
