























































import Vue from "vue";
import SearchInput from "fs-pb-vue2/components/SearchInput.vue";
import Slider from "@/components/app/Slider.vue";
import { getModule } from "vuex-module-decorators";
import { ContractsStore } from "fs-pb-vue2/store/modules/contracts";
import BaseContract from "@/components/contracts/BaseContract.vue";
import Card from "@/components/contracts/Card.vue";
import { ContractsModel } from "fs-pb-vue2/models/contracts/contractsModel";

/**Страница ифнормации по счету */
export default Vue.extend({
  components: {
    // ContractsPanel,
    Slider,
    Card,
    BaseContract,
    SearchInput
    // ChoosenPayments,
    // CurrencyRates
  },
  data: () => ({
    query: ''
  }),
  methods: {
    searchCatalog(e: any) {
      this.$router.push(`/payments?search=${e.query}`);
    }
  },
  computed: {
    contractStore(): ContractsStore {
      return getModule(ContractsStore, this.$store);
    },
    contracts(): ContractsModel {
      return this.contractStore.contracts;
    },
    activeCard(): string {
      if (this.contracts && this.contracts.getActiveCard) {
        return this.contracts?.getActiveCard()?.Data?.Id;
      }
      return null;
    },
    homeContract(): string {
      return this.contracts.HomeContract?.Data?.Id;
    }
  }
});
