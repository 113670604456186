




























































































import { ChatVue } from "fs-pb-vue2/vueModels/app/ChatVue";
import { Component } from "vue-property-decorator";
import {Message} from "fs-pb-vue2/data/chat";

@Component({})
export default class Chat extends ChatVue {
  async mounted() {
    await this.init();
  }

  previousMessage: Message;
  printDate(message: Message) {
    const text = "";
    
    if (this.previousMessage == undefined){
      this.previousMessage = message;
      return `${new Date(message.CreatedAt).getDate()} ${this.months[new Date(message.CreatedAt).getMonth()]} ${new Date(message.CreatedAt).getFullYear()}`
    }
    
    if (new Date(this.previousMessage?.CreatedAt).getDate() !== new Date(message.CreatedAt).getDate()){
      this.previousMessage = message;
      return `${new Date(message.CreatedAt).getDate()} ${this.months[new Date(message.CreatedAt).getMonth()]} ${new Date(message.CreatedAt).getFullYear()}`
    }
    
    this.previousMessage = message;

    return text;
  }
}
