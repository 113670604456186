import { Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";

export class SliderVueFS extends Vue {

  /**Стор настроек */
  get configStore(): ConfigStore {
    return getModule(ConfigStore, this.$store);
  }

  get slidesPR(): Array<any> {
    return this.configStore.products;
  }

  @Prop() _cardsOnDisplay!: number;

  @Watch('isMounted', { immediate: true })
  mountedComp(val: boolean) {
    if (val) {
      this.$forceUpdate();
    }
  }

  @Watch('slidesPR', { immediate: true })
  slidesComes(val: Array<any>) {
    if (val)
      val.forEach((item: any) => this.slides.push(item));
  }

  /** Переход по ссылки на другой сайт */
  goToAnotherPage(item) {
    if (item.IsOperation)
      this.$router.push(`/operation/0/${item.CatalogId}`);
    else
      window.open(item.Url, '_blank');
  }

  isMounted: boolean = false;

  slides: Array<any> = [];

  /** Позиция слайдера в шагах */
  sliderPosition: number = 0;

  cardsOnDisplay: number = this._cardsOnDisplay;

  /** Ширина окна браузера */
  windowWidth: number = 0;

  sliderOffset: number = 5000;

  stepSize: number = -300;

  slidesPushIndex: number = 0;

  slidesUnshiftIndex: number = null;

  refProd: any = this.$refs["prod-0"];
  
  step(next: boolean) {
    if (this.$refs["prod-0"]) {
      this.refProd = this.$refs["prod-0"];
      this.stepSize = -1 * this.refProd[0].offsetWidth;
    }
    if (this.slides !== null && this.slides !== undefined) {
      if (next) {
        if (this.sliderPosition === this.slides.length - this.cardsOnDisplay) {
          this.slides.push(this.slidesPR[this.slidesPushIndex]);
          this.slidesPushIndex += 1;
          if (this.slidesPushIndex === this.slidesPR.length) {
            this.slidesPushIndex = 0;
          }
        }
        setTimeout(() => {
          this.sliderPosition += 1;      
        }, 100)
      } else {
        if (this.sliderPosition <= 0) {
          if (this.slidesUnshiftIndex === null || this.slidesUnshiftIndex === -1)
            this.slidesUnshiftIndex = this.slidesPR.length - 1;
          document.getElementById('viewProt-slideR').classList.add("no-transition");
          this.sliderPosition += 1;
          this.slides.unshift(this.slidesPR[this.slidesUnshiftIndex]);
          this.slidesUnshiftIndex -= 1;
        }
        setTimeout(() => {
          document.getElementById('viewProt-slideR').classList.remove("no-transition");
          this.sliderPosition -= 1;          
        }, 100)
      }
      // if ( this.handle ) clearTimeout(this.handle);
      // this.handle = setTimeout(() => this.step(next), this.sliderOffset);
    }
  }

  updateWidth() {
    if (
      this.slides &&
      this.slides.length > 0 &&
      this.slides.length - this.cardsOnDisplay < this.sliderPosition
    ) {
      this.sliderPosition = this.slides.length - this.cardsOnDisplay;
    }
    if (window.innerWidth <= 1660) {
      this.cardsOnDisplay = 3;
    } if (window.innerWidth < 1550) {
      this.cardsOnDisplay = 2;
    } else if (window.innerWidth < 960) {
      this.cardsOnDisplay = 1;
    } else {
      this.cardsOnDisplay = this._cardsOnDisplay;
    }
    if (this.$refs && this.$refs["prod-0"]) {
      this.refProd = this.$refs["prod-0"];
      this.stepSize = -1 * this.refProd[0].offsetWidth;
    }
  }
}
