var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings default-container"},[_c('back-link',{staticClass:"mb-5",attrs:{"text":_vm.$t('main.header.links.settings')}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"7","lg":"8","md":"9"}},[(_vm.personLoading || _vm.configLoading)?_c('div',[_c('v-skeleton-loader',{staticClass:"fs-shadow-btn mb-5",attrs:{"height":"48","max-width":"551","type":"image"}}),_c('v-skeleton-loader',{staticClass:"mb-5",attrs:{"height":"25","max-width":"160","type":"heading"}}),_vm._l((3),function(n){return _c('v-skeleton-loader',{key:("settings-left-load-" + n),staticClass:"fs-shadow-btn mb-5",attrs:{"height":"48","max-width":"551","type":"image"}})}),_c('v-skeleton-loader',{staticClass:"mb-5",attrs:{"height":"25","max-width":"160","type":"heading"}}),_c('v-skeleton-loader',{staticClass:"fs-shadow-btn mb-5",attrs:{"height":"48","max-width":"551","type":"image"}}),_c('v-skeleton-loader',{staticClass:"fs-shadow-btn mb-5",attrs:{"height":"48","max-width":"551","type":"image"}})],2):(!_vm.configLoading && !_vm.personLoading)?_c('div',{staticClass:"d-block settings--main-actions"},[(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetSbpDefaultBank
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item profile-link-btn",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.$router.push('/profile')}}},[_c('v-icon',{staticClass:"profile-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.personInfo.FI)+" ")],1):_vm._e(),(_vm.configStore.apiData.ChargesEnable)?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.$router.push('/subscriptionList')}}},[_c('v-icon',{staticClass:"charges-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.charges"))+" ")],1):_vm._e(),_c('h2',{staticClass:"settings--subtitle"},[_vm._v(_vm._s(_vm.$t("main.settings.account")))]),(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetSbpDefaultBank
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openDialog(_vm.operationsList.aliasEdit)}}},[_c('v-icon',{staticClass:"login-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.editAlias"))+" ")],1):_vm._e(),(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetSbpDefaultBank
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openDialog(_vm.operationsList.passwordEdit)}}},[_c('v-icon',{staticClass:"password-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.changePassword"))+" ")],1):_vm._e(),_c('div',{staticClass:"fs-control-item mb-5 settings--main-actions--item"},[_c('p',{staticClass:"fs-control-item--text"},[_vm._v(" "+_vm._s(_vm.$t("main.settings.showHiddenItems"))+" ")]),_c('v-switch',{staticClass:"fs-control-item--control fs-mini-switcher",attrs:{"inset":"","color":"white"},on:{"click":function($event){return _vm.setShowHiddenItems()}},model:{value:(_vm.showHiddenItems),callback:function ($$v) {_vm.showHiddenItems=$$v},expression:"showHiddenItems"}})],1),_c('h2',{staticClass:"settings--subtitle"},[_vm._v(" "+_vm._s(_vm.$t("main.settings.ebsRegistration"))+" ")]),(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetSbpDefaultBank
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openAnotherSite($event, '/Storage/Gosuslugi/gosuslugi_open.html')}}},[_c('v-icon',{staticClass:"ebs-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.ebsText"))+" ")],1):_vm._e(),_c('h2',{staticClass:"settings--subtitle"},[_vm._v(" "+_vm._s(_vm.$t("main.settings.esiaRegistration"))+" ")]),(_vm.apiData)?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openDialog(_vm.operationsList.esiaRegistration)}}},[_c('v-icon',{staticClass:"esia-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.esiaBtnText"))+" ")],1):_vm._e(),_c('h2',{staticClass:"settings--subtitle"},[_vm._v(_vm._s(_vm.$t("main.settings.titleSBP")))]),(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetSbpDefaultBank
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openDialog(_vm.operationsList.sbpDefault)}}},[_c('v-icon',{staticClass:"sbp-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.sbpDefault"))+" ")],1):_vm._e(),(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetSbpDefaultBank
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openDialog(_vm.operationsList.sbpDeleteDefault)}}},[_c('v-icon',{staticClass:"sbp-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.sbpDeleteDefault"))+" ")],1):_vm._e(),(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetSbpEnable
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openDialog(_vm.operationsList.sbpEnable)}}},[_c('v-icon',{staticClass:"sbp-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.sbpEnable"))+" ")],1):_vm._e(),(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetDefaultInAccount
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openDialog(_vm.operationsList.accountInDefault)}}},[_c('v-icon',{staticClass:"sbp-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.sbpDefaultInContract"))+" ")],1):_vm._e(),(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetDefaultOutAccount
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openDialog(_vm.operationsList.setDefaultOutAccount)}}},[_c('v-icon',{staticClass:"sbp-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.sbpDefaultContract"))+" ")],1):_vm._e(),(
              _vm.apiData &&
              _vm.apiData.OperationCodeOfSetSbpMe2MeAcceptedBanks
            )?_c('v-btn',{staticClass:"fs-white-btn mb-5 settings--main-actions--item",attrs:{"height":"48","max-width":"551","color":"white"},on:{"click":function($event){return _vm.openDialog(_vm.operationsList.selectBanks)}}},[_c('v-icon',{staticClass:"sbp-icon fs-image-item",attrs:{"left":""}}),_vm._v(" "+_vm._s(_vm.$t("main.settings.sbpBanks"))+" ")],1):_vm._e()],1):_vm._e()])],1),_c('v-dialog',{attrs:{"scrollable":false,"max-width":"460px","persistent":true},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[(_vm.modalShow)?_c(_vm.activeOperation,{tag:"component",on:{"hideSelf":function($event){_vm.modalShow = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }