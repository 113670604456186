var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filteredDepositItems && _vm.filteredDepositItems.length > 0)?_c('div',[_c('div',{class:("block-header-wrap " + (_vm.isSingleCol ? 'mb-4' : 'mb-7'))},[_c('h1',{staticClass:"block-header block-header-second"},[_vm._v(" "+_vm._s(_vm.headerTitle)+" ")])]),_vm._l((_vm.filteredDepositItems),function(deposit){return _c('div',{key:deposit.Data.Id},[_c('v-list-item',{class:("contracts-group " + (_vm.isSelectedContract(deposit.Data.Id) ? 'contracts-group--selected-card' : '')),attrs:{"color":"black"}},[(_vm.isSingleCol)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{class:("fs-image-item " + (deposit.Data.ContractType == 5 ? 'credit-icon' : 'piggy-icon'))}),_c('v-list-item-content',_vm._g(_vm._b({staticClass:"contracts-group--link"},'v-list-item-content',attrs,false),on),[_c('router-link',{attrs:{"to":("/contract/" + (deposit.Data.Id))}},[_c('v-list-item-title',{staticClass:"contracts-group--header--title",domProps:{"textContent":_vm._s(deposit.Data.Alias)}}),_c('div',{staticClass:"sum-container"},[_c('v-list-item-subtitle',{staticClass:"contracts-group--header--subtitle",domProps:{"innerHTML":_vm._s(
                    ("" + (deposit.Rest.toString().substr(0, deposit.Rest.toString().indexOf(',')+1)))
                  )}}),_c('span',[_vm._v(" "+_vm._s(((deposit.Rest.toString().substr(deposit.Rest.toString().indexOf(',')+1)) + " " + (deposit.Currency)))+" ")]),(!deposit.IsOpen && deposit.Data.ContractType !== 5)?_c('span',{staticClass:"close-text-panel"},[_vm._v(_vm._s(_vm.$t('main.contracts.details.close')))]):_vm._e()],1)],1)],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(((deposit.Rest) + " " + (deposit.Currency)))}})]):_c('v-list-item-content',_vm._g(_vm._b({},'v-list-item-content',_vm.attrs,false),_vm.on),[_c('v-list-item-subtitle',{staticClass:"contracts-group--header--subtitle",domProps:{"innerHTML":_vm._s(((deposit.DisposableLimit) + " " + (deposit.Currency)))}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"contracts-group--item--text",domProps:{"textContent":_vm._s(
              ((deposit.PercentRate) + "  " + (deposit.DateEnd != ''
                  ? '      до ' + deposit.DateEnd.split('-').join('.')
                  : ''))
            )}})],1)],1)],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }