










































































































import BackLink from "@/components/app/BackLink.vue";
import { CatalogVueFS } from "@/extensions/catalog/CatalogVueFS";
import CatalogIcon from "@/components/catalog/CatalogIcon.vue";
import { Component } from "vue-property-decorator";
import { searchCatalogById } from "fs-pb-vue2/utilities/index";
import Requisites from "@/components/contracts/Requisites.vue";
import OperationModal from "@/components/history/OperationModal.vue";
import { ContractsStore } from "fs-pb-vue2/store/modules/contracts";
import { getModule } from "vuex-module-decorators";
import { urlFilter } from "fs-pb-vue2/filters";

@Component({
  components: { CatalogIcon, BackLink, Requisites, OperationModal },
})
export default class Catalog extends CatalogVueFS {
  async mounted() {
    await this.initOperationModal();
  }
}
