














































import { CardPinModalVueFS } from "@/extensions/cardOperations/CardPinModalVueFS";
import { Component } from "vue-property-decorator";
import VerifyBox from "@/components/app/VerifyBox.vue";

@Component({
  components: { VerifyBox }
})
export default class CardPinModal extends CardPinModalVueFS {
  async mounted() {
    await this.init();
  }
}
