var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-card"},[(_vm.isOldClient)?_c('h1',{staticClass:"auth-card--title mb-8"},[_vm._v(" "+_vm._s(_vm.$t("auth.form.registerLink"))+" ")]):_vm._e(),_c('v-form',{ref:"form",attrs:{"disabled":_vm.execLoading && _vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.next()}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(!_vm.isOldClient)?_c('p',{staticClass:"auth-card--reg-text"},[_vm._v(" "+_vm._s(_vm.$t("auth.form.regText"))+" ")]):_vm._e(),(!_vm.isOldClient)?_c('p',{staticClass:"auth-card--reg-text"},[_vm._v(" "+_vm._s(_vm.$t("auth.form.toRegister").toUpperCase())+" ")]):_vm._e(),(!_vm.isOldClient)?_c('p',{staticClass:"auth-card--reg-text"},[_vm._v(" "+_vm._s(_vm.$t("auth.form.inOurApp"))+" ")]):_vm._e(),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.OperationModel.PanMask),expression:"OperationModel.PanMask"}],attrs:{"height":"64","color":"grey","name":"pan","label":_vm.$t('auth.form.pan'),"outlined":"","rules":_vm.OperationModel.PanRules,"validate-on-blur":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('img',{attrs:{"width":"51","height":"33","src":require("./../../../public/img/card-input.png"),"alt":""}})]},proxy:true}]),model:{value:(_vm.OperationModel.Pan),callback:function ($$v) {_vm.$set(_vm.OperationModel, "Pan", $$v)},expression:"OperationModel.Pan"}}),_c('p',{staticClass:"auth-card--rules reg mt-2",domProps:{"innerHTML":_vm._s(
      _vm.$t('auth.form.registrationConditions', {
        rules: _vm.configStore.apiData ? _vm.configStore.apiData.DKBORulesFile : '',
        tarifs: _vm.configStore.apiData ? _vm.configStore.apiData.RegistrationTarifs : '',
      offerLoyalty: _vm.configStore.apiData ? _vm.configStore.apiData.OfferLoyaltyProgram : '',
      rulesLoyalty: _vm.configStore.apiData ? _vm.configStore.apiData.RulesLoyaltyProgram : ''
      })
    )}}),_c('div',{staticClass:"auth-card--submit-group"},[_c('v-btn',{staticClass:"fs-main-btn float-right",attrs:{"type":"submit","loading":_vm.execLoading}},[_vm._v(_vm._s(_vm.$t("auth.form.registerSubmit")))])],1),_c('div',{staticClass:"auth-card--alt-link"},[_c('p',{staticClass:"auth-card--alt-link--question mr-1 mb-1"},[_vm._v(" "+_vm._s(_vm.$t("auth.form.alreadyRegister"))+" ")]),_c('a',{staticClass:"fs-default-link auth-link",on:{"click":function($event){return _vm.toLogin()}}},[_vm._v(_vm._s(_vm.$t("auth.form.loginSubmit")))])]),_c('div',{staticClass:"auth-card--alt-link"},[_c('p',{staticClass:"auth-card--alt-link--question mr-1"},[_vm._v(" "+_vm._s(_vm.$t("auth.form.lostPassword"))+" ")]),_c('router-link',{staticClass:"fs-default-link auth-link",attrs:{"to":"/recovery"}},[_vm._v(_vm._s(_vm.$t("auth.form.toRecovery")))])],1)],1),_c('EbsEsia')],1)}
var staticRenderFns = []

export { render, staticRenderFns }