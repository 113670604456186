















import { Component } from "vue-property-decorator";
import { BaseOperationPropertyVue } from "fs-pb-vue2/vueModels/operations/properies/BaseOperationPropertyVue";

@Component({})
export default class ButtonProperty extends BaseOperationPropertyVue {}
