import i18n from "@/i18n";
import { ContractsTypes } from "fs-pb-vue2/data";
import { ContractModel } from "fs-pb-vue2/models/contracts/contractModel";
import { BaseContractVue } from "fs-pb-vue2/vueModels/contracts/BaseContractVue";

/** Модель представления счета */
export class BaseContractVueFS extends BaseContractVue {

  /**Правила проверки алиаса */
  aliasRules: Array<any> = [
    (value: any) => !!value || i18n.t("main.cards.errors.emptyAlias"), // проверка на пустоту
    (value: any) =>
      (value && value.length <= 20) ||
      i18n.t("main.cards.errors.maxLengthAlias") // проверка на максимальную длину алиаса
  ];

  /* Массив ключей дейстив для упорядочивания и наличия */
  get actionsOrder(): Array<string> {
    const type: ContractsTypes = this.contract.Data.ContractType;
    if (type === ContractsTypes.CardAccount) {
      return ['fill', 'pay', 'statement', 'requisits', 'smsPacket'];
    } else if (type === ContractsTypes.CurrentAccount) {
      return ['fill', 'pay', 'statement', 'requisits'];
    } else if (type === ContractsTypes.CreditAccount) {
      return ['openNew'];
    } else if (type === ContractsTypes.TimeAccounts || type === ContractsTypes.DepositAccounts) {
      return ['fill', 'pay', 'closeDeposit', 'statement', 'requisits'];
    }
    return [];
  }

  get isSmsPacketEditable(): boolean {
      const packet = this.contractsStore.smsPackets.find((packet: any) => packet.Code == this.contract.Data.SmsPacketId);
      if (packet)
        return packet.BankSet != '1' && packet.Vip != '1';
      return true;
  }

  get isHiddenItemsVissible(): boolean {
    return this.configStore.isHiddenItemsVissible;
  }

  goToAnotherContract(flag: boolean): void {
    let index = 0;
    const myContracts: Array<ContractModel> = [];
    this.contracts.Contracts.map((con: any) => myContracts.push(con));
    if (this.contracts.Deposits.length > 0)
      this.contracts.Deposits.forEach((dep: ContractModel) => myContracts.push(dep));
    if (this.contracts.Credits.length > 0)
      this.contracts.Credits.forEach((dep: ContractModel) => myContracts.push(dep));
    myContracts.map((item, i, arr) => {
      if (item === this.contract) {
        if (flag === true) {
          if (i !== arr.length - 1) index = i + 1;
          else index = 0;
        } else {
          if (i !== 0) index = i - 1;
          else index = arr.length - 1;
        }
      }
    });
    const result = myContracts[index]?.Data.Id;
    this.$router.push(`/contract/${result}`);
  }

  get actionsByOrder(): Array<any> {
    const order: Array<any> = this.actionsOrder;
    const myActions: Array<any> = this.actions;
    if (order.length > 0) {
      const res: Array<any> = [];
      order.forEach((key: string) => {
        const action: any = myActions.find((action: any) => action.actionName === key);
        if (action)
          res.push(action);
      });
      return res;
    }
    return this.actions;
  }

  /**Список доступных операций */
  get actions(): Array<any> {
    const actions: Array<any> = [];
    if (this.contract) {
      if (
        this.contract.IsOpen &&
        this.contract.Data.CanDeposit &&
        this.configStore.apiData.OperationCodeOfToContract
      ) {
        /**Действие пополнения счета */
        actions.push({
          actionName: 'fill',
          text: i18n.t("main.contracts.actions.fill"),
          iconClass: "fill-card-icon",
          action: () => {
            this.$router.push(
              `/contractUpCatalog?id=${this.contract.Data.Id}&type=${this.contract.Data.ContractType}&contractTo=${this.contract.Data.ReferenceId}`
            );
          }
        });
      }
      if (this.contract.IsOpen && this.contract.Data.CanWithdraw) {
        actions.push({
          /**Действие оплаты со счета */
          actionName: 'pay',
          text: (this.contract.Data.ContractType === 2 || this.contract.Data.ContractType === 1) ? i18n.t("main.contracts.actions.payDeposit") : i18n.t("main.contracts.actions.pay"),
          iconClass: "pay-icon",
          action: () => {
            if (this.contract.Data.ContractType === 2 || this.contract.Data.ContractType === 1) 
                this.$router.push(`/operation/0/${this.configStore.apiData.OperationCodeOfToContract}?contract=${this.contract.Data.ReferenceId}`) 
            else
                this.$router.push(`/payments?contract=${this.contract.Data.ReferenceId}`);
          }
        });
      }
      if ((this.contract.Data.ContractType === 2 || this.contract.Data.ContractType === 1) &&  this.configStore.apiData.OperationCodeOfToContract) {
        if (this.contract.IsOpen && this.contract.Data.CanWithdraw) {
          actions.push({
            actionName: 'closeDeposit',
            text: this.$i18n.t("main.contracts.closeDeposit"),
            iconClass: "close-deposit-icon",
            action: () => {
              this.$router.push(
                `/operation/0/${this.configStore.apiData.OperationCodeOfToContract}?_categoryName=${this.$i18n.t("main.contracts.closeDeposit")}&contract=${this.contract.Data.ReferenceId}&sum=${this.contract.Data.Rest}`
              );
            }
          });
        }
        actions.push({
          actionName: 'openDeposit',
          text: this.$i18n.t("main.contracts.openDeposit"),
          iconClass: "open-deposit-icon",
          action: () => {
            this.$router.push(
              `/operation/0/${this.configStore.apiData.OperationCodeOfOpenDeposit}`
            );
          }
        });        
      }
      if ((this.contract.Data.ContractType === 2 && this.configStore.apiData.OperationCodeOfOpenDeposit) || (this.contract.Data.ContractType === 5 && this.configStore.apiData.OperationCodeOfCreditRequest)) {
        actions.push({
          actionName: 'openNew',
          text: this.$i18n.t("main.contracts.openNew"),
          iconClass: "open-deposit-icon",
          action: () => {
            this.contract.Data.ContractType === 2 
              ? this.$router.push(`/operation/0/${this.configStore.apiData.OperationCodeOfOpenDeposit}`)
              : this.$router.push(`/operation/0/${this.configStore.apiData.OperationCodeOfCreditRequest}`)
              },
        })
      }
      if (this.contract.Data.ContractType === 3 || this.contract.Data.ContractType === 4) {
        actions.push({
          actionName: 'tarif',
          text: i18n.t("main.contracts.actions.tarif"),
          iconClass: "tarif-icon",
          action: () => {
            window.open(`https://www.finsb.ru/individuals/everyday/bank-counts/tariffs.pdf`, '_blank');
          }
        });        
      }
      if (
        this.configStore.apiData.OperationCodeOfChangeSmsPacket &&
        !this.personInfoSMS &&
        this.contract.Data.ContractType === 4 &&
        this.isSmsPacketEditable
      ) {
        actions.push({
          actionName: 'smsPacket',
          text: i18n.t("main.contracts.actions.smsPacket"),
          iconClass: "sms-packet-icon",
          action: () => {
            this.dialogContent = new Object({
              vueName: "ContractSmsModal",
              params: {
                contractId: this.contract.Data.ReferenceId
              }
            });
            this.dialog = true;
          }
        });
      }
      // if (this.contract.Data.ContractType === 2) {
      //   /** Действие запроса графика для Вкладов */
      //   actions.push({
      //     text: i18n.t("main.contracts.actions.forecast", {
      //       inCome: `${this.contract.Data.FutureIncomeSum} ${this.contract.Currency}`
      //     }),
      //     iconClass: "statement-icon",
      //     action: async () => {
      //       this.forecastDialog = true;
      //       if (this.contract.DepositForeCast.length === 0) {
      //         await this.contractsStore.fetchForeCastDeposit(
      //           this.contract.Data.Id
      //         );
      //       }
      //     }
      //   });
      // }
      // if (this.contract.Data.ContractType === 5) {
      //   /** Действие запроса графика для кредитов */
      //   actions.push({
      //     text: i18n.t("main.contracts.actions.schedule"),
      //     iconClass: "statement-icon",
      //     action: async () => {
      //       this.scheduleDialog = true;
      //       if (!this.contract.CreditSchedule) {
      //         await this.contractsStore.fetchScheduleCredit(
      //           this.contract.Data.Id
      //         );
      //       }
      //     }
      //   });
      // }
      /**Действие запроса выписки по счету*/
      if (this.contract.Data.ContractType !== 5) {
        actions.push({
          actionName: 'statement',
          text: i18n.t("main.contracts.actions.statement"),
          iconClass: "statement-icon",
          action: () => {
            // console.log("Выписка");
            this.$router.push(`/statement/${this.contract.Data.Id}/0`);
          }
        });        
      }
      /**Действие запроса реквизитов счета */
      if (this.contract.IsOpen) {
        actions.push({
          actionName: 'requisits',
          text: i18n.t("main.contracts.actions.requisits"),
          iconClass: "requisits-icon",
          action: () => {
            this.showRequisites();
          }
        });
      }
    }
    return actions;
  }
  get details(): Array<any> {
    let details: Array<any> = [];
    if (this.contract) {
      if (this.contract.IsOpen) {
        details = [
          {
            disabled: this.setVisibilityLoading,
            name: i18n.t("main.contracts.details.show"),
            textVal: (isVisible: boolean) => { 
            return isVisible
            ? i18n.t("main.contracts.details.showContract")
            : i18n.t("main.contracts.details.hideContract")
          },
            isAction: true,
            model: !this.contract.Data.Hidden,
            action: async () => {
              await this.setVisibility();
            }
          }
        ];
      }
      // Карточный счет
      if (this.contract.Data.ContractType == ContractsTypes.CardAccount) {
        details.push({
          //Номер счета
          name: i18n.t("main.contracts.details.acntNum"),
          textVal: this.contract.Data.MainAccount
        });
        details.push({
          //имя пользователя
          name: i18n.t("main.contracts.details.personFIO"),
          textVal: this.personStore.personInfo.FIO
        });
        if (this.personInfoSMS) {
            details.push({
              name: i18n.t("main.contracts.details.smsPack"),
              textVal: `${
                this.contractsStore.smsPackets.find(
                  (item: any) => item.Code === this.personInfoSMS
                ).Title
              }`, //TODO: Заменить на текстовки смс пакетов
            });
        } else if (
          this.contractsStore.smsPackets &&
          this.contractsStore.smsPackets.find(
            (item: any) => item.Code === this.contract.Data.SmsPacketId
          ) &&
          this.contract.Data.SmsPacketId.trim() !== ""
        ) {
          // Пакет SMS информирования
          details.push({
            name: i18n.t("main.contracts.details.smsPack"),
            textVal: `${
              this.contractsStore.smsPackets.find(
                (item: any) => item.Code === this.contract.Data.SmsPacketId
              ).Title
            }` //TODO: Заменить на текстовки смс пакетов
          });
        }
        details.push({
          //Тип счёта
          name: i18n.t("main.contracts.details.type"),
          textVal: this.contract.ContractType
        });
        details.push({
          //Состояние
          name: i18n.t("main.contracts.details.state"),
          textVal: this.contract.IsOpen
            ? i18n.t("main.contracts.details.open")
            : i18n.t("main.contracts.details.close")
        });
        if (this.contract.DateOpen) {
          details.push({
            //Дата открытия
            name: i18n.t("main.contracts.details.dateOpen"),
            textVal: this.contract.DateOpen
          });
        }
        details.push({
          //Валюта счета
          name: i18n.t("main.contracts.details.currency"),
          textVal: this.contract.Currency
        });
        if (this.contract.Data.FullLimit > 0) {
          details.push({
            // Лимит овердрафта
            name: i18n.t("main.contracts.details.fullLimit"),
            textVal: `${this.contract.FullLimit} ${this.contract.Currency}`,
          });
          if (new Date(this.contract.GraceOutstandingDate).toString() == 'Invalid Date') {
            details.push({
              // Льготный период
              name: i18n.t("main.contracts.details.gracePeriodText"),
              isWrapped: true,
              textVal: this.contract.Data.FullLimit <= 0
                  ? "-"
                  : this.contract.GraceOutstandingDate
            });          
          } else {
            details.push({
              // Льготный период
              name: i18n.t("main.contracts.details.gracePeriodText"),
              isWrapped: true,
              textVal: this.contract.Data.FullLimit <= 0
                  ? "-"
                  : this.contract.Data.GraceOutstandingDate != null
                  ? i18n.t("main.contracts.details.graceOutstandingText", {
                      graceOutstandingDate: this.contract.GraceOutstandingDate,
                    })
                  : i18n.t("main.contracts.details.graceEndText", {
                      graceEndDate: this.contract.GraceOutstandingDate,
                    }),
            });          
          }
        } 
        if (this.contract.Data.CalcUsedLimit === 0) {
          details.push({
            // Остаток по счету
            name: i18n.t("main.contracts.details.contractRest"),
            textVal: `${this.contract.Rest} ${this.contract.Currency}`,
          });
        } else {
          details.push({
            // Задолженность по счету
            name: i18n.t("main.contracts.details.calcUsedLimit"),
            textVal: `${this.contract.UsedLimit} ${this.contract.Currency}`,
          });
        }
        if (this.contract.IsOpen) {
          details.push(
            {
              // Заблокировано
              name: i18n.t("main.contracts.details.lockedSum"),
              textVal: `${this.contract.CalcLocked} ${this.contract.Currency}`,
            },
            {
              // Доступный остаток
              name: i18n.t("main.contracts.details.disposableLimit"),
              textVal: `${this.contract.DisposableLimit} ${this.contract.Currency}`,
            }
          );
        }
      }
      // Текущий счет
      if (this.contract.Data.ContractType == ContractsTypes.CurrentAccount) {
        details.push(
          {
            //Номер счета
            name: i18n.t("main.contracts.details.acntNum"),
            textVal: this.contract.Data.MainAccount,
          },
          {
            //имя пользователя
            name: i18n.t("main.contracts.details.personFIO"),
            textVal: this.personStore.personInfo.FIO
          },
          {
            //Тип счёта
            name: i18n.t("main.contracts.details.type"),
            textVal: this.contract.ContractType,
          },
          {
            //Состояние
            name: i18n.t("main.contracts.details.state"),
            textVal: this.contract.IsOpen
              ? i18n.t("main.contracts.details.open")
              : i18n.t("main.contracts.details.close"),
          },
          {
            //Дата открытия
            name: i18n.t("main.contracts.details.dateOpen"),
            textVal: this.contract.DateOpen
          },
          {
            //Валюта счета
            name: i18n.t("main.contracts.details.currency"),
            textVal: this.contract.Currency,
          },
          {
            // Остаток по счету
            name: i18n.t("main.contracts.details.contractRest"),
            textVal: `${this.contract.Rest} ${this.contract.Currency}`,
          }
        );
        if (this.contract.IsOpen) {
          details.push(
            {
              // Заблокировано
              name: i18n.t("main.contracts.details.lockedSum"),
              textVal: `${this.contract.CalcLocked} ${this.contract.Currency}`,
            },
            {
              // Доступный остаток
              name: i18n.t("main.contracts.details.disposableLimit"),
              textVal: `${this.contract.DisposableLimit} ${this.contract.Currency}`,
            }
          );
        }
      }
      // Вклад срочный
      if (this.contract.Data.ContractType == ContractsTypes.TimeAccounts) {
        details.push(
          {
            //Номер счета
            name: i18n.t("main.contracts.details.acntNum"),
            textVal: this.contract.Data.MainAccount,
          },
          {
            //имя пользователя
            name: i18n.t("main.contracts.details.personFIO"),
            textVal: this.personStore.personInfo.FIO
          },
          {
            //Тип счёта
            name: i18n.t("main.contracts.details.type"),
            textVal: this.contract.Data.DepositName,
          },
          {
            //Состояние
            name: i18n.t("main.contracts.details.state"),
            textVal: this.contract.IsOpen
              ? i18n.t("main.contracts.details.open")
              : i18n.t("main.contracts.details.close"),
          },
          {
            //Дата открытия
            name: i18n.t("main.contracts.details.dateOpen"),
            textVal: this.contract.Data.DateOpen
              ? this.$options.filters.date(
                  this.contract.Data.DateOpen,
                  "dateext"
                )
              : "",
          },
          {
            // Срок вклада
            name: i18n.t("main.contracts.details.depositEnd"),
            textVal: this.contract.Data.DateEnd
              ? this.$options.filters.date(
                  this.contract.Data.DateEnd,
                  "dateext"
                )
              : "",
          },
          {
            //Валюта счета
            name: i18n.t("main.contracts.details.currency"),
            textVal: this.contract.Currency,
          },
          {
            // Сумма вклада
            name: i18n.t("main.contracts.details.depositSum"),
            textVal: `${this.contract.Rest} ${this.contract.Currency}`,
          },
          {
            // Начальная сумма вклада
            name: i18n.t("main.contracts.details.initialDepositSum"),
            textVal: `${this.contract.DepositSum} ${this.contract.Currency}`,
          },
          {
            // Процентная ставка
            name: i18n.t("main.contracts.details.percentRate"),
            textVal: this.contract.PercentRate,
          }
        );
        if (this.contract.Data.DateClose) {
          details.splice(6, 0, {
            name: i18n.t("main.contracts.details.dateClose"),
            textVal: this.$options.filters.date(this.contract.Data.DateClose, "dateext")
          });

        }
        if (this.contract.IsOpen) {
          details.push({
            // Пополнение
            name: i18n.t("main.contracts.details.canDeposit"),
            textVal: this.contract.Data.CanDeposit
              ? i18n.t("main.contracts.details.yes")
              : i18n.t("main.contracts.details.no"),
          });
        }
        details.push(
          {
            // Минимальная сумма пополнения
            name: i18n.t("main.contracts.details.minPaySumDeposit"),
            textVal: `${this.$options.filters.sum(this.contract.Data.MinDepositAmount)} ${this.contract.Currency}`,
          },
        );
        if (this.contract.IsOpen) {
          details.push({
            // Частичное снятие
            name: i18n.t("main.contracts.details.partCanWithdraw"),
            textVal: this.contract.Data.CanWithdraw
              ? i18n.t("main.contracts.details.yes")
              : i18n.t("main.contracts.details.no"),
          });
        }
        details.push(
          {
            // Неснижаемый остаток
            name: i18n.t("main.contracts.details.minDepositum"),
            textVal: `${this.contract.MinDepositRest} ${this.contract.Currency}`,
          },
          {
            // Капитализация процентов
            name: i18n.t("main.contracts.details.isCapitalization"),
            textVal: this.contract.Data.Capitalization
              ? i18n.t("main.contracts.details.yes")
              : i18n.t("main.contracts.details.no"),
          },
          {
            // Ставка при досрочном изъятии
            name: i18n.t("main.contracts.details.earlyPercent"),
            textVal: this.contract.EarlyPercent,
          },
        );
      }
      // Вклад до востребования
      if (this.contract.Data.ContractType == ContractsTypes.DepositAccounts) {
        details.push(
          {
            //Номер счета
            name: i18n.t("main.contracts.details.acntNum"),
            textVal: this.contract.Data.MainAccount,
          },
          {
            //имя пользователя
            name: i18n.t("main.contracts.details.personFIO"),
            textVal: this.personStore.personInfo.FIO
          },
          {
            //Тип счёта
            name: i18n.t("main.contracts.details.type"),
            textVal: this.contract.Data.DepositName,
          },
          {
            //Состояние
            name: i18n.t("main.contracts.details.state"),
            textVal: this.contract.IsOpen
              ? i18n.t("main.contracts.details.open")
              : i18n.t("main.contracts.details.close"),
          },
          {
            //Дата открытия
            name: i18n.t("main.contracts.details.dateOpen"),
            textVal: this.contract.Data.DateOpen
              ? this.$options.filters.date(
                  this.contract.Data.DateOpen,
                  "dateext"
                )
              : "",
          },
          {
            //Валюта счета
            name: i18n.t("main.contracts.details.currency"),
            textVal: this.contract.Currency,
          },
          // {
          //   // Сумма вклада
          // },
          {
            // Процентная ставка
            name: i18n.t("main.contracts.details.percentRate"),
            textVal: this.contract.PercentRate,
          }
        );
        if (this.contract.IsOpen) {
          details.push({
            // Пополнение
            name: i18n.t("main.contracts.details.canDeposit"),
            textVal: this.contract.Data.CanDeposit
              ? i18n.t("main.contracts.details.yes")
              : i18n.t("main.contracts.details.no"),
          });
        }
        details.push(
          {
            // Минимальная сумма пополнения
            name: i18n.t("main.contracts.details.minPaySumDeposit"),
            textVal: `${this.$options.filters.sum(this.contract.Data.MinDepositAmount)} ${this.contract.Currency}`,
          },
        );
        if (this.contract.IsOpen) {
          details.push({
            // Частичное снятие
            name: i18n.t("main.contracts.details.partCanWithdraw"),
            textVal: this.contract.Data.CanWithdraw
              ? i18n.t("main.contracts.details.yes")
              : i18n.t("main.contracts.details.no"),
          });
        }
        details.push({
          // Неснижаемый остаток
          name: i18n.t("main.contracts.details.minDepositum"),
          textVal: `${this.contract.MinDepositRest} ${this.contract.Currency}`,
        });
      }
      // Кредитный счет
      if (this.contract.Data.ContractType == ContractsTypes.CreditAccount) {
        details.push(
          {
            //Номер счета
            name: i18n.t("main.contracts.details.acntNum"),
            textVal: this.contract.Data.MainAccount,
          },
          {
            //имя пользователя
            name: i18n.t("main.contracts.details.personFIO"),
            textVal: this.personStore.personInfo.FIO
          },
          {
            //Тип счёта
            name: i18n.t("main.contracts.details.type"),
            textVal: this.contract.Data.DepositName,
          },
          {
            //Состояние
            name: i18n.t("main.contracts.details.state"),
            textVal: this.contract.IsOpen
              ? i18n.t("main.contracts.details.open")
              : i18n.t("main.contracts.details.close"),
          },
          {
            //Дата открытия
            name: i18n.t("main.contracts.details.dateOpen"),
            textVal: this.contract.Data.DateOpen
              ? this.$options.filters.date(
                  this.contract.Data.DateOpen,
                  "dateext"
                )
              : "",
          },
          {
            //Валюта счета
            name: i18n.t("main.contracts.details.currency"),
            textVal: this.contract.Currency,
          },
          {
            // Задолженность по кредиту
            name: i18n.t("main.contracts.details.arrearsCredit"),
            textVal: `${this.contract.DisposableLimit} ${this.contract.Currency}`,
          },
          {
            // Первоначальная сумма кредита
            name: i18n.t("main.contracts.details.minDepositRest"),
            textVal: `${this.contract.DepositSum} ${this.contract.Currency}`,
          },
          {
            // Процентная ставка
            name: i18n.t("main.contracts.details.percentRate"),
            textVal: this.contract.PercentRate,
          },
          {
            // Дата выдачи кредита
            name: i18n.t("main.contracts.details.creditStartDate"),
            textVal: this.contract.Data.OpenCredit,
          },
          {
            // Срок кредита
            name: i18n.t("main.contracts.details.creditEnd"),
            textVal: this.contract.Data.DateEnd
              ? this.$options.filters.date(
                  this.contract.Data.DateEnd,
                  "dateext"
                )
              : "",
          },
          {
            // Дата очередного платежа
            name: i18n.t("main.contracts.details.nextPaymentDate"),
            textVal: this.contract.Data.MinPayDate
              ? this.$options.filters.date(
                  this.contract.Data.MinPayDate,
                  "dateext"
                )
              : "",
          },
          {
            // Сумма очередного платежа
            name: i18n.t("main.contracts.details.nextPaymentSum"),
            textVal: `${this.contract.MinPaySum} ${this.contract.Currency}`,
          },
          {
            // Просрочка, сумма
            name: i18n.t("main.contracts.details.delaySum"),
            textVal: `${this.contract.Data.OutstandingSum} ${this.contract.Currency}`,
          },
          {
            // Просрочка, дни
            name: i18n.t("main.contracts.details.delayDate"),
            textVal: this.contract.OutstandingDelayDate,
          },
          {
            // Залог
            name: i18n.t("main.contracts.details.pledge"),
            textVal: this.contract.Data.Pledge,
          },
          {
            // Поручитель
            name: i18n.t("main.contracts.details.guarantor"),
            textVal: this.contract.Data.Guarantor,
          },
          {
            // Дата очередного страхового платежа
            name: i18n.t("main.contracts.details.dateInsurance"),
            textVal: this.contract.Data.DateInsurance
              ? this.$options.filters.date(
                  this.contract.Data.DateInsurance,
                  "dateext"
                )
              : "",
          },
          {
            // Сумма страхового платежа
            name: i18n.t("main.contracts.details.amountInsurance"),
            textVal: `${this.contract.AmountInsurance} ${this.contract.Currency}`,
          }
        );
      }
    }
    return details;
  }
}