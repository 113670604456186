import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/home/Home.vue";
import Login from "@/views/auth/Login.vue";
import EbsInfo from "@/views/auth/EbsInfo.vue";
import Register from "@/views/auth/Register.vue";
import Recovery from "@/views/auth/Recovery.vue";
// import About from "@/views/About.vue";
import Contract from "@/views/contracts/Contract.vue";
// import Card from "@/views/contracts/Card.vue";
import OperationsHistory from "@/views/history/OperationsHistory.vue";
import Statement from "@/views/history/Statement.vue";
import Operation from "@/views/operations/Operation.vue";
import Payments from "@/views/catalog/Catalog.vue";
import Charges from "@/views/charges/Charges.vue";
// import Operation from "@/views/catalog/Operation.vue";
import Templates from "@/views/templates/Templates.vue";
import Settings from "@/views/settings/Settings.vue";
import Profile from "@/views/profile/Profile.vue";
import Notifications from "@/views/notifications/Notifications.vue";
import AuthHistory from "@/views/authHistory/AuthHistory.vue";
import SubscriptionList from "@/views/subscription/SubscriptionList.vue";
// import Error from "@/views/Error.vue";
import Product from "@/views/product/Products.vue";
import ContractUpCatalog from "@/views/catalog/ContractUpCatalog.vue";
import Cashback from "@/views/cashback/Cashback.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    /**Главная страница*/
    path: "*",
    name: "Home",
    meta: { layout: "main", requiresAuth: true },
    component: Home
  },
  {
    /**Главная страница*/
    path: "/authHistory",
    name: "authHistory",
    meta: { layout: "main", requiresAuth: true },
    component: AuthHistory
  },
  {
    /**Главная страница*/
    path: "/subscriptionList",
    name: "SubscriptionList",
    meta: { layout: "main", requiresAuth: true },
    component: SubscriptionList
  },
  {
    path: "/cashback",
    name: "Cashback",
    meta: { layout: "main", requiresAuth: true },
    component: Cashback
  },
  {
    /**Страница входа */
    path: "/login",
    name: "Login",
    meta: { layout: "auth", requiresVisitors: true },
    component: Login
  },
  {
    /**Страница входа */
    path: "/ebsInfo",
    name: "EbsInfo",
    meta: { layout: "auth", requiresVisitors: true },
    component: EbsInfo
  },
  {
    path: '/contractUpCatalog',
    name: 'ContractUpCatalog',
    meta: { layout: "main", requiresAuth: true },
    component: ContractUpCatalog
  },
  {
    /**Страница регистрации */
    path: "/register",
    name: "Register",
    meta: { layout: "auth", requiresVisitors: true },
    component: Register
  },
  {
    /** Страница начислений */
    path: "/charges",
    name: "Charges",
    meta: { layout: "main", requiresAuth: true },
    component: Charges
  },
  {
    /**Страница восстановления доступа */
    path: "/recovery",
    name: "Recovery",
    meta: { layout: "auth", requiresVisitors: true },
    component: Recovery
  },
  {
    /**Страница данных по счёту */
    path: "/contract/:contractId",
    name: "Contract",
    props: (route) => ({ reqs: route.query.reqs }),
    meta: { layout: "main", requiresAuth: true },
    component: Contract
  },
  {
    /**Страница данных по карте */
    path: "/card/:contractId/:cardId",
    name: "Card",
    meta: { layout: "main", requiresAuth: true },
    component: Contract
  },
  {
    /**Страница данных по счёту */
    path: "/statement/:contractId/:cardId" || "/statement/:contractId",
    name: "CardStatement",
    meta: { layout: "main", requiresAuth: true },
    component: Statement
  },
  {
    /**Страница каталога операций */
    path: "/payments",
    name: "Payments",
    props: (route) => ({ search: route.query.search }),
    meta: { layout: "main", requiresAuth: true },
    component: Payments
  },
  {
    /**Страница исполнения операции */
    path: "/operation/:type/:id",
    name: "Operation",
    props: route => ({ continue: route.query.continue }),
    meta: { layout: "main", requiresAuth: true },
    component: Operation
  },
  {
    /**Страница истории операций */
    path: "/operations",
    name: "OperationsHistory",
    props: route => ({ operId: route.query.operId }),
    meta: { layout: "main", requiresAuth: true },
    component: OperationsHistory
  },
  {
    /**Страница истории операций */
    path: "/templates",
    name: "Templates",
    meta: { layout: "main", requiresAuth: true },
    component: Templates
  },
  {
    /**Страница настроек */
    path: "/settings",
    name: "Settings",
    props: route => ({ sbpOpen: route.query.sbpOpen }),
    meta: { layout: "main", requiresAuth: true },
    component: Settings
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { layout: "main", requiresAuth: true },
    component: Profile
  },
  {
    path: "/notifications",
    name: "Notifications",
    meta: { layout: "main", requiresAuth: true },
    component: Notifications
  },
  {
    /**Главная страница*/
    path: "/products",
    name: "Produst",
    meta: { layout: "main", requiresAuth: true },
    component: Product
  },
  // {
  //   path: "/error",
  //   name: "Error",
  //   meta: { layout: "empty" },
  //   component: Error
  // }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes
});

export default router;
