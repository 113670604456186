var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"templates default-container"},[_c('back-link',{staticClass:"mb-5"}),_c('h1',{staticClass:"fs-header mb-3"},[_vm._v(_vm._s(_vm.$t("main.templates.title")))]),_c('v-tabs',{model:{value:(_vm.tempatesActiveHref),callback:function ($$v) {_vm.tempatesActiveHref=$$v},expression:"tempatesActiveHref"}},[_c('v-tabs-slider'),_c('v-tab',{staticClass:"templates--selector",attrs:{"href":"#simple","active-class":"active-selector"}},[_vm._v(" "+_vm._s(_vm.$t("main.templates.simple"))+" ")]),_c('v-tab',{staticClass:"templates--selector",attrs:{"href":"#period","active-class":"active-selector"}},[_vm._v(" "+_vm._s(_vm.$t("main.templates.period"))+" ")]),_c('v-tab-item',{staticClass:"pt-5",attrs:{"value":"simple"}},[(_vm.storeLoading)?_c('div',[_c('v-row',_vm._l((5),function(m){return _c('v-col',{key:("template-loader-" + m),attrs:{"col":"12","xs":"6","sm":"4","lg":"2"}},[_c('div',{staticClass:"template-item"},[_c('v-skeleton-loader',{staticClass:"mr-4",attrs:{"type":"avatar"}}),_c('div',{staticClass:"mt-2"},[_c('v-skeleton-loader',{attrs:{"type":"text","height":"20","width":"70"}}),_c('v-skeleton-loader',{attrs:{"type":"text","height":"20","width":"50"}})],1)],1)])}),1)],1):(_vm.simpleTemplates && _vm.simpleTemplates.length)?_c('div',[_c('v-row',_vm._l((_vm.simpleTemplates),function(temp,index){return _c('v-col',{key:("templates-grid-childs-" + index),attrs:{"col":"12","xs":"6","sm":"4","lg":"2"}},[(temp.IsActive)?_c('div',{staticClass:"template-item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"template-item--data",on:{"click":function($event){$event.preventDefault();return _vm.toTemplate(temp)}}},'div',attrs,false),on),[(temp.Picture)?_c('img',{staticClass:"template-item--data--image",attrs:{"src":temp.Picture}}):_c('img',{staticClass:"template-item--data--image",attrs:{"src":require("./../../../public/img/default-oper.png")}}),_c('p',{staticClass:"template-item--data--caption"},[_vm._v(" "+_vm._s(temp.Name)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.createPayment")))])]),_c('div',{staticClass:"template-item--actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editTemplate(temp)}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"fs-image-item edit-icon"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteTemplate(temp)}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"fs-image-item close-icon"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.delete")))])])],1)],1):_c('div',{staticClass:"template-item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"template-item--data"},'div',attrs,false),on),[(temp.Picture)?_c('img',{staticClass:"template-item--data--image",attrs:{"src":temp.Picture}}):_c('img',{staticClass:"template-item--data--image",attrs:{"src":require("./../../../public/img/default-oper.png")}}),_c('p',{staticClass:"template-item--data--caption"},[_vm._v(" "+_vm._s(temp.Name)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.errors.outdated")))])]),_c('div',{staticClass:"template-item--actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteTemplate(temp)}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"fs-image-item close-icon"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.delete")))])])],1)],1)])}),1)],1):_c('div',[_vm._v(" "+_vm._s(_vm.$t("main.templates.empty"))+" ")])]),_c('v-tab-item',{staticClass:"pt-5",attrs:{"value":"period"}},[(_vm.storeLoading)?_c('div',[_c('v-row',_vm._l((5),function(m){return _c('v-col',{key:("template-loader-" + m),attrs:{"col":"12","xs":"6","sm":"4","lg":"2"}},[_c('div',{staticClass:"template-item"},[_c('v-skeleton-loader',{staticClass:"mr-4",attrs:{"type":"avatar"}}),_c('div',{staticClass:"mt-2"},[_c('v-skeleton-loader',{attrs:{"type":"text","height":"20","width":"70"}}),_c('v-skeleton-loader',{attrs:{"type":"text","height":"20","width":"50"}})],1)],1),_c('v-skeleton-loader',{staticClass:"template-item--data--caption mt-3",attrs:{"type":"text","width":"160"}}),_c('v-skeleton-loader',{staticClass:"template-item--data--caption mt-1",attrs:{"type":"text","width":"180"}})],1)}),1)],1):(_vm.periodTemplates && _vm.periodTemplates.length)?_c('div',[_c('v-row',_vm._l((_vm.periodTemplates),function(temp,index){return _c('v-col',{key:("templates-grid-childs-" + index),attrs:{"col":"12","xs":"6","sm":"4","lg":"2"}},[(temp.IsActive)?_c('div',[_c('div',{staticClass:"template-item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"template-item--data",on:{"click":function($event){$event.preventDefault();return _vm.toTemplate(temp)}}},'div',attrs,false),on),[(temp.Picture)?_c('img',{staticClass:"template-item--data--image",attrs:{"src":temp.Picture}}):_c('img',{staticClass:"template-item--data--image",attrs:{"src":require("./../../../public/img/default-oper.png")}}),_c('p',{staticClass:"template-item--data--caption"},[_vm._v(" "+_vm._s(temp.Name)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.createPayment")))])]),_c('div',{staticClass:"template-item--actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editTemplate(temp)}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"fs-image-item edit-icon"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteTemplate(temp)}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"fs-image-item close-icon"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.delete")))])])],1)],1),_c('p',{staticClass:"template-item--data--caption mt-3"},[_vm._v(" "+_vm._s(((_vm.$t("main.templates.nextDate")) + ":"))+" ")]),_c('p',{staticClass:"template-item--data--caption mt-1"},[_vm._v(" "+_vm._s(_vm.displayTemplatePeriod(temp))+" ")])]):_c('div',{staticClass:"template-item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"template-item--data"},'div',attrs,false),on),[(temp.Picture)?_c('img',{staticClass:"template-item--data--image",attrs:{"src":temp.Picture}}):_c('img',{staticClass:"template-item--data--image",attrs:{"src":require("./../../../public/img/default-oper.png")}}),_c('p',{staticClass:"template-item--data--caption"},[_vm._v(" "+_vm._s(temp.Name)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.errors.outdated")))])]),_c('div',{staticClass:"template-item--actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteTemplate(temp)}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"fs-image-item close-icon"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.delete")))])])],1)],1)])}),1)],1):_c('div',[_vm._v(" "+_vm._s(_vm.$t("main.templates.empty"))+" ")])])],1),_c('fs-template-dialog',{attrs:{"mode":"TemplateEditService","operationCaption":_vm.selectedTemplate.Caption,"templateData":_vm.selectedTemplate},on:{"hideSelf":function($event){_vm.editMode = false}},model:{value:(_vm.editMode),callback:function ($$v) {_vm.editMode=$$v},expression:"editMode"}}),_c('v-dialog',{attrs:{"scrollable":false,"max-width":"460px"},model:{value:(_vm.deleteMode),callback:function ($$v) {_vm.deleteMode=$$v},expression:"deleteMode"}},[_c('v-card',{staticClass:"fs-default-dialog"},[_c('v-form',{ref:"aliasForm",on:{"submit":function($event){$event.preventDefault();return _vm.deleteTemplateHandler()}}},[_c('div',{staticClass:"d-flex dialog-content"},[_c('h1',{staticClass:"fs-header"},[_vm._v(" "+_vm._s(_vm.$t("main.templates.deleteTemplate", { name: _vm.selectedTemplate.Name }))+" ")])]),_c('div',{staticClass:"dialog-content dialog-content-btns row mt-0 pt-0 pb-10"},[_c('v-btn',{staticClass:"fs-main-btn fs-btn-br",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t("main.templates.delete")))]),_c('a',{staticClass:"fs-cencel-link",on:{"click":function($event){$event.preventDefault();return _vm.cencelDelete()}}},[_vm._v(_vm._s(_vm.$t("main.templates.cencel")))])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }