








import Vue from "vue";
import CatalogOperation from "@/components/operations/CatalogOperation.vue";
import DepositOperation from "@/components/operations/DepositOperation.vue";
import CreditOperation from "@/components/operations/CreditOperation.vue";
import { getModule } from "vuex-module-decorators";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";

/**Страница ифнормации по счету */
export default Vue.extend({
  components: {
    CatalogOperation,
    DepositOperation,
    CreditOperation
  },
  computed: {
    configStore(): ConfigStore {
      return getModule(ConfigStore, this.$store);
    },
    isOpenOperation(): string {
      if (this.configStore.operationCodesWithAlertSuccessState.length > 0) {
        const operId: string = this.configStore.operationCodesWithAlertSuccessState.find((code: string) => code === this.operId.toString());
        if (operId === this.configStore.apiData.OperationCodeOfCreditRequest)
          return 'credit';
        else if (operId === this.configStore.apiData.OperationCodeOfOpenDeposit)
          return 'deposit';
      }
      return '';
    },
    operId(): string {
      return this.$route.params.id;
    },
    operInitType(): string {
      return this.$route.params.type;
    }
  }
});
