















































































































import { RegisterVue } from "fs-pb-vue2/vueModels/auth/RegisterVue";
import EbsEsia from "@/components/auth/EbsEsia.vue";
import { Component } from "vue-property-decorator";
import { getCookie, setCookie } from "fs-pb-vue2/utilities/cookieHelper";
import { getModule } from "vuex-module-decorators";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";

@Component({
  components: { EbsEsia }
})
export default class Register extends RegisterVue {
  get configStore(): ConfigStore {
    return getModule(ConfigStore, this.$store);
  }

  async mounted() {
    await this.init();
  }

  toLogin(): void {
    if(!this.isOldClient)
      setCookie("isOldClient", "true");
    this.$router.push(`/login`);
  }

  get isOldClient(): boolean {
    return getCookie("isOldClient") == "true";
  }

  async afterExecute(): Promise<void> {   
    this.$router.push('/login');   
  }
}
