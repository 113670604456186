







import Vue from "vue";
import BackLink from "@/components/app/BackLink.vue";
import SubscriptionList from "@/components/subscription/SubscriptionList.vue";

/**Страница ифнормации по счету */
export default Vue.extend({
  components: {
    BackLink,
    SubscriptionList
  }
});
