import i18n from "@/i18n";
import { AuthNavigationVue } from "fs-pb-vue2/vueModels/navigation/AuthNavigationVue";
import { appConfig } from "fs-pb-vue2/config";

export class AuthNavigationVueFS extends AuthNavigationVue {

  get windowWidthCalc(): number {
    return window.innerWidth;
  }

  /** Список ссылок в меню */
  links: Array<any> = [
    {
      title: i18n.t("auth.nav.aboutBank"),
      url: appConfig.aboutBankUrl,
      exact: true
    },
    {
      title: i18n.t("auth.nav.aboutSystem"),
      url: appConfig.aboutSystemUrl,
      exact: true
    },
    {
      title: i18n.t("auth.nav.atm"),
      url: appConfig.atmUrl,
      exact: true
    },
    {
      title: i18n.t("auth.nav.contacts"),
      url: appConfig.contactsUrl
    }
  ];
}
