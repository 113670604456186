import { Prop, Vue, Watch } from "vue-property-decorator";
import { RequestsStates } from "fs-pb-vue2/data/enums/requestsStates";
import { getModule } from "vuex-module-decorators";
import { ContractModel } from "fs-pb-vue2/models/contracts/contractModel";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";
import { OperationModalVue } from "fs-pb-vue2/vueModels/history/OperationModalVue";
import { OperationsHistoryApi } from "fs-pb-vue2/services/api/OperationsHistoryApi";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import { container } from "@/inversify.config";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import i18n from "@/i18n";

export class OperationModalVueFS extends OperationModalVue {

  // @watch:

  @Watch('details', { immediate: true })
  async detailsComes(val: any) {
    if (val && val.RequestData) {
      await this.init();
    }
  }

  // @computed: 

  get isStatement(): boolean {
    return !this.$route.fullPath.includes('?operId');
  }

  get testEmail(): () => void {
    return () => {
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      this.isEmailValid = reg.test(this.email);
    };
  }

  get isOperaionCodeEqualsSBP(): boolean {
    return this.details.RequestData.respondentCode === this.configStore.apiData.OperationCodeOfSbpOperation;
  }

  get operStatusText(): string {
    let res: string = '';
    switch (this.operationData.Status) {
      case 1:
        res = i18n.t("main.operation.form.operExecutedStatus").toString();
        break;
      case 3:
        res = i18n.t("main.operation.form.operExecutingStatus").toString();
        break;
      default:
        break;
    }
    return res;
  }

  get alertService(): IAlertService {
    return container.get<IAlertService>(DiTokens.AlertService);
  }

  /**Стор счетов */
  get configStore(): ConfigStore {
    return getModule(ConfigStore, this.$store);
  }

  get contractAlias(): string {
    return this.contractsStore.contracts.getContractByRefId(this.details.RequestData.contractRef)?.Data?.Alias;
  }

  get isSBP(): boolean {
    return !!this.details.RequestData?.sbpID || !!this.details?.OperationData?.IsSbp;
  }

  /**
   * Заголовок операции в модальном окне
   */
  get operCaption(): string {
    return !this.isStatement && this.isOperaionCodeEqualsSBP && this.isSBP ? 'Переводы по СБП' : this.operationData.Category ? this.operationData.Category : this.respondentData.Category
  }

  get docCategory(): string {
    const index = this.details.RequestData.keys.indexOf("docCategory");
    if (index != -1)
      return this.details.RequestData.values[index]
    else
      return null;
  }
  // @data:

  // Детали для вывода на экран
  moreDetails: Array<any> = [];

  // Шаблонные детали (если имеется respondentCode)
  templateDetails: any = [
    {
      isVissible: false,
      value: null,
      key: 'sum',
      withCurrency: true,
      name: i18n.t('main.operations.historyDetails.sum')
    },
    {
      isVissible: false,
      value: null,
      key: 'tariff',
      withCurrency: true,
      name: i18n.t('main.operations.historyDetails.tariff')
    },
    {
      isVissible: false,
      value: null,
      key: 'receiverName',
      name: i18n.t('main.operations.historyDetails.receiverName')
    },
    {
      isVissible: false,
      value: null,
      key: 'phoneNumber',
      name: i18n.t('main.operations.historyDetails.phoneNumber')
    },
    {
      isVissible: false,
      value: null,
      key: 'contractTo',
      name: i18n.t('main.operations.historyDetails.contractTo')
    },
    {
      isVissible: false,
      value: null,
      key: 'acnt',
      name: i18n.t('main.operations.historyDetails.contractTo')
    },
    {
      isVissible: false,
      value: null,
      key: 'card',
      name: i18n.t('main.operations.historyDetails.card')
    },
    {
      isVissible: false,
      value: null,
      key: 'CARDINT',
      name: i18n.t('main.operations.historyDetails.card')
    },
    {
      isVissible: false,
      value: null,
      key: 'receiverBank',
      name: i18n.t('main.operations.historyDetails.receiverBank')
    },
    {
      isVissible: false,
      value: null,
      key: 'bankName',
      name: i18n.t('main.operations.historyDetails.receiverBank')
    },
    {
      isVissible: false,
      value: null,
      key: 'bank',
      name: i18n.t('main.operations.historyDetails.receiverBank')
    },
    {
      isVissible: false,
      isWrapped: true,
      value: null,
      key: 'sbpID',
      name: i18n.t('main.operations.historyDetails.sbpID')
    },
    {
      isVissible: false,
      value: null,
      key: 'percent',
      name: i18n.t('main.operations.historyDetails.percent')
    },
    {
      isVissible: false,
      value: null,
      key: 'length',
      name: i18n.t('main.operations.historyDetails.length')
    },
    {
      isVissible: false,
      isWrapped: true,
      value: null,
      key: 'important',
      name: i18n.t('main.operations.historyDetails.important')
    },
    {
      isVissible: false,
      isWrapped: true,
      value: null,
      key: 'messageToClient',
      name: i18n.t('main.operations.historyDetails.messageToClient')
    }
  ];

  // Заголовок деталей
  moreDetailsPreView: string = '';

  printLoading: boolean = false;

  printMenuShow: boolean = false;

  emailOpen: boolean = false;

  email: string = '';

  emailLoading: boolean = false;

  isEmailValid: boolean = true;

  noDetails: boolean = false;

  // @methods:

  async init(): Promise<void> {
    this.moreDetails = [];
    this.templateDetails.map((detail: any) => {
      detail.isVissible = false;
      detail.value = null;
    })
    if (this.details.sbpDetails) {
      for (const key in this.details.sbpDetails) {
        const value: any = this.details.sbpDetails[key];
        if (!key.includes('_')) {
          this.moreDetails.push({
            isVissible: true,
            isWrapped: true,
            value: value,
            key: key,
            name: key          
          });
        }
      }
      this.moreDetailsPreView = this.operationData.Sum > 0 ? "Счет зачисления" : "Счет списания";
      this.noDetails = true;
      return;
    }
    this.moreDetails = this.templateDetails;
    const catId: string = this.details.RequestData.respondentCode;

    this.moreDetailsPreView = this.operationData.Sum > 0 ? "Счет зачисления" : "Счет списания";

    if (!catId && catId !== '0') {
      this.moreDetails = this.details.Details;
      this.moreDetails.map((detail: any) => {
        detail.isVissible = true;
        detail.isWrapped = true;
      });
      this.noDetails = true;
    }
    else {
      const vissibleKeys: Array<string> = this.getKeysByOperationCode(catId);
      this.moreDetails.forEach((detail: any) => detail.isVissible = vissibleKeys.find((key: string) => key == detail.key) ? true : false);
      this.noDetails = false;
      this.loadDetailsValues();
    }
  }

  stringRequestStatusSate(state: RequestsStates, desc: string): string {
    let stringState = "";
    switch (state) {
      case RequestsStates.Executed:
        stringState = i18n.t("main.operation.form.operExecutedStatus").toString();
        break;
      case RequestsStates.Prepared:
      case RequestsStates.Preparing:
      case RequestsStates.Executing:
        stringState = i18n.t("main.operation.form.operExecutingStatus").toString()
        break;
      case RequestsStates.PrepareError:
      case RequestsStates.ExecuteError:
      case RequestsStates.Denied:
        stringState = i18n
          .t("main.operation.form.stateDenied", { errorText: desc })
          .toString();
        break;
      default:
        stringState = i18n.t("main.operation.form.stateDenied").toString();
        break;
    }
    return stringState;
  }

  togglePrintMenu(): void {
    this.printMenuShow = !this.printMenuShow;
    // this.$options.filters.url(`Operations/Print/${this.operationData.Id || this.details.RequestData.operationId}`);
  }

  async goPDF(): Promise<void> {
    this.printLoading = true;
    try {
      //const link: string = await OperationsHistoryApi.getOperationPdfAsync(this.operationData.Id || this.details.RequestData.operationId);
      const html = await OperationsHistoryApi.getOperDetailsHtml(this.operationData.Id || this.details.RequestData.operationId);
      if (html) {
        const link = await OperationsHistoryApi.getHtmlToPdfConvertAsync(html, 'Квитанция');
        const a = document.createElement("a");
        a.setAttribute('href', link);
        a.setAttribute('target', '_blank');
        a.click();
        a.remove();
      }
    } catch (e: any) {
      this.alertService.error(e.message);
      console.log(e);
    }
    this.printLoading = false;
  }

  toggleEmail(flag: boolean): void {
    this.emailOpen = flag;
    this.printMenuShow = false;
  }

  async sendEmail(): Promise<void> {
    this.emailLoading = true;
    this.testEmail();
    if (this.isEmailValid) {
      try {
        await OperationsHistoryApi.sendOperationCheckForEmail(this.operationData.Id || this.details.RequestData.operationId, this.email, this.details.RequestData.respondentCode);
        this.alertService.success(i18n.t("main.contracts.messages.successEmail").toString());
        this.toggleEmail(false);
        this.togglePrintMenu();
      } catch (e) {
        console.warn(e);
      }
    }
    this.emailLoading = false;
  }

  loadDetailsValues(): void {
    this.moreDetails.forEach((detail: any) => {
      if (detail.key !== 'phoneNumber') {
        const index: number = this.details.RequestData.keys.indexOf(detail.key);
        if (index !== -1) {
          let value: string = this.details.RequestData.values[index];
          if (detail.key == 'card') {
            const values: Array<string> = value.split('|');
            if (values.length === 4)
              value = values[1];
          }
          detail.value = value;
          if (detail.withCurrency) {
            detail.value += ` ${this.$options.filters.currency(this.operationData.Currency)}`;
          }
        }
      } else if (detail.key == 'phoneNumber') {
        const index: number = this.details.RequestData.keys.indexOf('contractTo');
        const index2: number = this.details.RequestData.keys.indexOf('phone');
        const index3: number = this.details.RequestData.keys.indexOf('mphone');
        detail.value = index !== -1 ? this.details.RequestData.values[index] :
        index2 !== -1 && this.details.RequestData.values[index2] ? this.details.RequestData.values[index2] :
        index3 !== -1 && this.details.RequestData.values[index3] ? this.details.RequestData.values[index3] : null;
        // detail.value = index !== -1 && this.details.RequestData.values[index].substring(0,2) == '+7' ? this.details.RequestData.values[index] :
        // index2 !== -1 && this.details.RequestData.values[index2] ? this.details.RequestData.values[index2] :
        // index3 !== -1 && this.details.RequestData.values[index3] ? this.details.RequestData.values[index3] : null;
       if(detail.value.substring(0,2) == '+7') 
        detail.value = this.$options.filters.phoneNumber(detail.value, true);
      }
    });

    const sumDetail: any = this.moreDetails.find((detail: any) => detail.key == 'sum');
    const tariffDetail: any = this.moreDetails.find((detail: any) => detail.key == 'tariff');

    if (this.details.RequestData.respondentCode == this.configStore.apiData.OperationCodeOfSbpOperation) {
      const index: number = this.details.RequestData.keys.indexOf('type');
      if (index !== -1 && this.details.RequestData.values[index] == 'SBP') {
        sumDetail.isVissible = true;
        tariffDetail.isVissible = true;
      }
    } else if (
      (this.details.RequestData.respondentCode == this.configStore.apiData.OperationCodeOfMe2MeOperation || 
      this.details.RequestData.respondentCode == this.configStore.apiData.OperationCodeOfSbpC2BOperation) && sumDetail.value < 0) {
        if (this.details.RequestData.respondentCode == this.configStore.apiData.OperationCodeOfSbpC2BOperation)
          sumDetail.name = i18n.t('main.operations.historyDetails.sumC2B')
        sumDetail.isVissible = true;
        tariffDetail.isVissible = true;  
    }
  }

  getKeysByOperationCode(categoryId: string): Array<string> {
    if (this.isSBP) {
      if (categoryId != this.configStore.apiData.OperationCodeOfMe2MeOperation)
        return ['phoneNumber', 'bankName', 'sbpID', 'receiverName', 'messageToClient'];
      else
        return ['phoneNumber', 'bankName', 'receiverName', 'messageToClient'];
    }
    switch (categoryId) {
      case this.configStore.apiData.OperationCodeOfOpenDeposit:
        return ['length', 'percent', 'important'];
      case this.configStore.apiData.OperationCodeOfSbpOperation:
        return ['phoneNumber', 'bankName', 'messageToClient'];
      case this.configStore.apiData.OperationCodeOfToOtherContractByCard:
        return ['receiverBank', 'card'];
      case this.configStore.apiData.OperationCodeOfKazakhstan:
        return ['CARDINT'];
      case this.configStore.apiData.OperationCodeOfToContract:
        return ['receiverBank', 'contractTo'];
      case this.configStore.apiData.OperationCodeOfToExt:
        return ['tariff', 'acnt', 'bank'];
      default:
        return ['receiverName', 'receiverBank', 'phoneNumber', 'card', 'messageToClient'];
    }
  }

}
