








































import { AuthNavigationVueFS } from "@/extensions/navigation/AuthNavigationVueFS";
import { Component } from "vue-property-decorator";

@Component()
export default class AuthNavigation extends AuthNavigationVueFS {
  // mounted() {
  //   this.links = [
  //     { title: this.$i18n.t("auth.nav.aboutBank"), url: "https://www.finsb.ru/about/", exact: true },
  //     // { title: this.$i18n.t("auth.nav.aboutSystem"), url: "https://www.finsb.ru/individuals/everyday/internet-bank/", },
  //     { title: this.$i18n.t("auth.nav.atm"), url: "https://www.finsb.ru/about/offices/" },
  //     { title: this.$i18n.t("auth.nav.contacts"), url: "https://www.finsb.ru/about/contacts/" },
  //   ]
  // }
}
