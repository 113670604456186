import { inject, injectable } from "inversify";
import BaseAuthService from "fs-pb-vue2/services/auth/BaseAuthService";
import { FinServLoginModel } from "@/extensions/models/auth/FinServLoginModel";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import IErrorService from "fs-pb-vue2/services/error/IErrorService";
import ISessionService from "fs-pb-vue2/services/auth/ISessionService";
import { getCookie, setCookie } from "fs-pb-vue2/utilities/cookieHelper";
import { ActionStateEnum } from "fs-pb-vue2/models/enums/operStateEnum";

@injectable()
/**Сервис авторизации клиента (по логину и паролю)*/
export default class FinServAuthService extends BaseAuthService {
  constructor(
    @inject(DiTokens.AlertService) private alertService: IAlertService,
    @inject(DiTokens.ErrorService) private errorService: IErrorService,
    @inject(DiTokens.SessionService) sessionService: ISessionService
  ) { super(sessionService); }

  public async init(params: any): Promise<FinServLoginModel> {
    const model: FinServLoginModel = new FinServLoginModel();
    try {
      model.RememberMe = getCookie('rememberMe') === 'true';

      if (this.configStore.passwordRequirements.isEmpty()) {
        await this.configStore.fetchPasswordRequirements();
      }
      model.PasswordRequirements = this.configStore.passwordRequirements;

      model.LoginType = getCookie('loginMode');

      if (["pan", "login"].indexOf(model.LoginType) === -1)
        model.LoginType = "login";


      model.ActionStateText = this.authStore.msg;
      this.authStore.setMsg("");
      if (model.ActionStateText)
        this.alertService.success(
          model.ActionStateText, false
        );

      if (this.authStore.login) {
        model.Pan = this.authStore.login;
        model.LoginType = "pan";
        this.authStore.setLogin("");
      } else if (model.RememberMe) {
        if (model.LoginType == "login")
          model.Alias = getCookie('rememberedLogin')
        else if (model.LoginType == "pan")
          model.Pan = getCookie('rememberedLogin')
      }
    }
    catch (e) {
      await this.errorService.errorHandler(e, true, false, "")
    }
    return model;
  }

  /**
   * Вход
   * @param loginModel Модель авторизации
   * @returns Модель авторизации
   */
  public async login(loginModel: FinServLoginModel): Promise<FinServLoginModel> {
    try {
      loginModel.Login = loginModel.LoginType == "pan" ? loginModel.Pan.replace(/\s/g, '') : loginModel.Alias;
      loginModel = await super.login(loginModel) as FinServLoginModel;
      if (!loginModel.IsSuccess) {
        const autoHide = !loginModel._isChangePassword;
        await this.alertService.show(loginModel.ActionStateText, loginModel.MsgType, autoHide);
      } else {
        setCookie('isOldClient', 'true');
        setCookie('rememberMe', loginModel.RememberMe.toString());
        setCookie('rememberedLogin', loginModel.RememberMe ? (loginModel.LoginType == "pan" ? loginModel.Pan : loginModel.Alias) : "");
        setCookie('loginMode', loginModel.LoginType);
      }
    } catch (e) {
      loginModel.ActionStateText = e.message;
      loginModel._actionState = ActionStateEnum.Error;
      await this.errorService.errorHandler(e, false, false, "");
    }
    return loginModel;
  }
}
