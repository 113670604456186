






































































































































































































import { ProfileVue } from "fs-pb-vue2/vueModels/profile/ProfileVue";
import { Component } from "vue-property-decorator";

@Component({})
export default class Profile extends ProfileVue {}
