import { inject, injectable } from "inversify";
import ContractService from "fs-pb-vue2/services/contracts/ContractService";
import { ContractsApi } from "fs-pb-vue2/services/api/ContractsApi";
import { SMSPacket } from "fs-pb-vue2/data/smsPacket";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import IErrorService from "fs-pb-vue2/services/error/IErrorService";

@injectable()
/**Сервис счетов */
export default class ContractServiceFS extends ContractService {
  constructor(
    @inject(DiTokens.AlertService) private alertService: IAlertService,
    @inject(DiTokens.ErrorService) private errorService: IErrorService
  ) { super(alertService, errorService) }


  public async getSmsPackets(): Promise<Array<SMSPacket>> {
    try {
      const packets: Array<SMSPacket> = await ContractsApi.getSMSpackets();
      if (packets !== undefined && packets !== null) {
        return packets.filter(
          (item: any) => !!item.Code
        );
      }
    } catch (e) {
      await this.errorService.errorHandler(e, false, true, "");
    }
    return [];
  }

}
