






























import i18n from "@/i18n";
import Vue from "vue";
// import Chat from "@/components/app/chat";

export default Vue.extend({
  name: "common-footer",
  // components: {
  //   Chat
  // }
});
